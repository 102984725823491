import NavbarPage from "../Navbar/navbarPage";
import * as Icon from 'react-feather';
import FooterPage from "../Footer/footerPage";
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import projects from '../Models/projects.json';

const ProjectDetails = () => {
    const params = useParams();
    const id = params.id;
    // console.log(params.id);

    const [project, setProject] = useState([]);
    const [similarProjects, setSimilarProjects] = useState([]);

    useEffect(() => {
        const data = projects.filter(each => each.project_slug === id);
        const currentIndex = projects.findIndex(each => each.project_slug === id);
        // console.log(currentIndex)
        const nextProjects = [];
        for (let i = 1; i <=3; i++) {
            const nextIndex = (currentIndex + i) % projects.length;
            nextProjects.push(projects[nextIndex]);
        }
        // console.log("nextBlogs", nextProjects)
        setSimilarProjects(nextProjects);
        setProject(data);

        window.scrollTo(0,0);
    }, [id]);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12 other-page-nav-color"}>
                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  project details  */}
                    <div className={"row my-3 justify-content-center"}>
                        {project.map(each => (
                            <div className={"col-md-10 col-11 my-md-5 my-3"} key={each.id}>

                                <div className={"row bg-blue br-16"}>
                                    <div className={"col-12 p-0"}>
                                        <img className={"img-fluid w-100 br-top-16"}
                                             src={each.project_landing_image}
                                             alt={"project-details-hero-image"}/>
                                    </div>
                                    <div className={"col-12 px-md-4 px-3 py-3 py-md-5"}>
                                        <div className={"row justify-content-between py-md-2 px-md-3"}>
                                            <div className={"col-md-6 p-md-0"}>
                                                <h1
                                                    className={"font-size-56  font-weight-400 font-zaf-renaissance font-color-brand-light"}>
                                                    {each.project_name}
                                                </h1>
                                                <div className={"mt-3 w-100"}>
                                                    <label
                                                        className={"font-size-16 font-weight-305 font-color-brand-light"}>
                                                        {each.project_description}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={"col-md-5 mt-4 pt-2 mt-md-0 pt-md-0"}>
                                                <div className={"d-flex flex-column"}>
                                                    <label
                                                        className={"font-color-brand-light font-size-24 font-weight-700"}>Service</label>
                                                    {each.project_services.map((eachService, index) => (
                                                        <div className={"mt-2"} key={index}>
                                                            <label
                                                                className={"font-color-brand-light font-size-16 font-weight-305"}>{eachService}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={"d-flex flex-column my-4"}>
                                                    <label
                                                        className={"font-color-brand-light font-size-24 font-weight-700"}>Client</label>
                                                    <div className={"mt-2"}>
                                                        <label
                                                            className={"font-color-brand-light font-size-16 font-weight-305"}>{each.project_client}</label>
                                                    </div>
                                                </div>
                                                <div className={"d-flex flex-column"}>
                                                    <label
                                                        className={"font-color-brand-light font-size-24 font-weight-700"}>Date</label>
                                                    <div className={"mt-2"}>
                                                        <label
                                                            className={"font-color-brand-light font-size-16 font-weight-305"}>{each.project_date}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*  project images  */}
                                {each.project_images.map((projectImages, index) => (
                                    projectImages.length === 3 && (
                                        <div className={"row mt-md-2 mt-3"} key={index}>
                                            {index % 2 === 0 ? (
                                                <>
                                                    <div className={"col-12 mt-md-4 p-0"}>
                                                        <img className={"img-fluid w-100 br-32"}
                                                             src={projectImages[0].image_url}
                                                             alt={projectImages[0].alt}/>
                                                    </div>
                                                    <div className={"col-12 mt-md-4 mt-3 p-0"}>
                                                        <div className={"row justify-content-between mt-md-2"}>
                                                            <div className={"col-md-8 col-12"}>
                                                                <img className={"img-fluid w-100 br-32 h-100"}
                                                                     src={projectImages[1].image_url}
                                                                     alt={projectImages[1].alt}/>
                                                            </div>
                                                            <div className={"col-md-4 col-12 mt-3 mt-md-0"}>
                                                                <img className={"img-fluid br-32 w-100 h-100"}
                                                                     src={projectImages[2].image_url}
                                                                     alt={projectImages[2].alt}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            ) : (
                                                <>
                                                    <div className={"col-12 mt-md-4 p-0"}>
                                                        <img className={"img-fluid w-100 h-100 br-32"}
                                                             src={projectImages[0].image_url}
                                                             alt={projectImages[0].alt}/>
                                                    </div>
                                                    <div className={"col-12 mt-md-4 mt-3 p-0"}>
                                                        <div className={"row justify-content-between mt-md-2"}>
                                                            <div className={"col-md-4 col-12"}>
                                                                <img className={"img-fluid w-100 br-32 h-100"}
                                                                     src={projectImages[1].image_url}
                                                                     alt={projectImages[1].alt}/>
                                                            </div>
                                                            <div className={"col-md-8 col-12 mt-3 mt-md-0"}>
                                                                <img className={"img-fluid w-100 h-100 br-32"}
                                                                     src={projectImages[2].image_url}
                                                                     alt={projectImages[2].alt}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )
                                ))}


                                {/*<div className={"row mt-md-2 mt-3"}>*/}
                                {/*    <div className={"col-12 mt-md-4 p-0"}>*/}
                                {/*        <img className={"img-fluid w-100 h-100 br-32"}*/}
                                {/*             src={"/images/project-details/roads-over-bridge-image-4.png"}*/}
                                {/*             alt={"roads-over-bridge-image-1"}/>*/}
                                {/*    </div>*/}
                                {/*    <div className={"col-12 mt-md-4 mt-3 p-0"}>*/}
                                {/*        <div className={"row justify-content-between mt-md-2"}>*/}
                                {/*            <div className={"col-md-4 col-12"}>*/}
                                {/*                <img className={"img-fluid br-32 h-100"}*/}
                                {/*                     src={"/images/project-details/roads-over-bridge-image-5.png"}*/}
                                {/*                     alt={"roads-over-bridge-image-2"}/>*/}
                                {/*            </div>*/}
                                {/*            <div className={"col-md-8 col-12 mt-3 mt-md-0"}>*/}
                                {/*                <img className={"img-fluid br-32"}*/}
                                {/*                     src={"/images/project-details/roads-over-bridge-image-6.png"}*/}
                                {/*                     alt={"roads-over-bridge-image-3"}/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        ))}
                    </div>

                    {/*  Have a project in mind?  */}
                    <div className={"row bg-promotion-container"}>
                        <div className={"col-12"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-8 py-md-5 py-4 my-md-5 "}>
                                    <div className={"row justify-content-center"}>
                                        <div className={"col-md-10 col-11"}>
                                            <label className={"font-size-18 font-sequel-sans-medium font-weight-315 font-color-orange"}>
                                                Have a project in mind?
                                            </label>
                                            <div className={"mt-md-4 mt-2"}>
                                                <h3 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                                    We'll work closely with you to understand your needs and create a
                                                    custom
                                                    design that reflects your style and values.
                                                </h3>
                                            </div>
                                            <div className={"mt-4 mt-md-3"}>
                                                <Link to={"/contact-us"}>
                                                    <button type={"button"}
                                                            className={"bg-contact-us-btn font-sequel-sans-medium border-0 bg-orange font-color-blue font-size-16 font-weight-315"}>
                                                        Contact Us
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-4 d-md-block d-none p-0"}>
                                    <img className={"img-fluid w-100 h-100"}
                                         src={"/images/home/night-view-bridge-image.png"}
                                         alt={"night-view-bridge-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  similar projects  */}
                    <div className={"row my-3 justify-content-center"}>
                        <div className={"col-12 col-md-10 my-md-5 my-3"}>
                            <div className={"w-100 d-flex justify-content-center"}>
                                <label className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Similar
                                    Projects</label>
                            </div>

                            <div className={"row my-4 py-md-3 row-gap-5"}>
                                {similarProjects.map((eachProject, index) => (
                                    <div className={"col-md-4 col-12"} key={index}>
                                        <Link to={`/project-details/${eachProject.project_slug}`} style={{textDecoration: "none"}}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div
                                                    className={"image-container overflow-hidden  position-relative b-2-light-brown br-16"}>
                                                    <img className={"img-fluid w-100"}
                                                         src={eachProject.project_card_image}
                                                         alt={"project-image"}/>
                                                </div>
                                                <div className={"w-100 d-flex flex-column mt-3"}>
                                                    <label className={"font-color-orange font-size-14 font-weight-305"}>
                                                        {eachProject.project_tag}
                                                    </label>
                                                    <div className={"my-2"}>
                                                        <label
                                                            className={"font-color-blue font-size-24 font-zaf-renaissance text-truncate w-100 font-weight-400"}>
                                                            {eachProject.project_name}
                                                        </label>
                                                    </div>
                                                    <label className={"font-color-blue font-size-16 font-weight-305"}>
                                                        {eachProject.project_client}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                ))}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-1.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400"}>Road*/}
                                {/*                        Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12 mt-md-0"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-1.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400"}>Road*/}
                                {/*                        Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12 mt-md-0"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/bridge-image-1.png"} alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label*/}
                                {/*                    className={"font-color-orange font-size-14 font-weight-305"}>BRIDGE</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label*/}
                                {/*                        className={"font-color-blue font-size-24 font-weight-400 text-truncate w-100"}>Station*/}
                                {/*                        building Bayappanahalli, Third coaching terminal</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className={"w-100 mt-4 d-flex justify-content-center"}>
                                <Link to={"/projects"}>
                                    <button type={'button'}
                                            className={'bg-contact-us-btn border-0 bg-orange font-sequel-sans-medium font-color-blue font-weight-315 font-size-16'}>View
                                        All <Icon.ArrowRight color={'#081250'} size={19}/>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/*    footer   */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails;
