import NavbarPage from "../Navbar/navbarPage";
import {Accordion} from "react-bootstrap";
import * as Icon from 'react-feather';
import FooterPage from "../Footer/footerPage";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import careersData from '../Models/careers.json';

const Careers = () => {
    const [jobsData, setJobsData] = useState([]);
    const [mouseHover, setMouseHover] = useState();

    useEffect(() => {
        setJobsData(careersData);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={'container-fluid'}>
            <div className={"row bg-white"}>
                <div className={'col-12 other-page-nav-color'}>

                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={'row'}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  careers  */}
                    <div className={"row justify-content-center my-md-5"}>
                        <div className={"col-12 col-md-10 py-4 my-2"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label
                                    className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Careers</label>
                            </div>

                            {/*  accordion job details*/}
                            <div className={"row mt-md-5 justify-content-center"}>
                                <div className={"col-10 mt-4 d-flex pt-md-2"}>
                                    <label className={"font-color-blue text-center font-size-32 font-weight-400"}>Currently, there are no openings. Will get back to you soon.</label>
                                </div>
                                <div className={"col-12 d-none mt-4 pt-md-2"}>
                                    <Accordion defaultActiveKey="0" flush>
                                        {jobsData.map((eachJob, index) => (
                                            <Accordion.Item eventKey={`${index}`} className={"bb-1-light-brown"}>
                                                <Accordion.Header
                                                    className={"py-4 font-zaf-renaissance"}>{eachJob.job_title}</Accordion.Header>
                                                <Accordion.Body className={"py-0 px-2"}>
                                                    <div className={"row row-gap-md-5 row-gap-3 mb-4 pb-md-2"}>
                                                        {eachJob.job_positions.map((eachPosition, index) => (
                                                            <div
                                                                className={`col-12 b-1-light-brown ${window.innerWidth < 768 ? 'br-4': 'br-16'} p-md-4 job-container p-3`}
                                                                key={index} onMouseEnter={() => setMouseHover(index)}
                                                                onMouseLeave={() => setMouseHover()}>
                                                                <Link to={`/job-application/${eachPosition.job_slug}`}>
                                                                    <div
                                                                        className={"w-100 d-flex justify-content-between align-items-center p-md-2"}>
                                                                        <div>
                                                                            <label
                                                                                className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-blue"}>{eachPosition.job_role}</label>
                                                                            <div className={"mt-md-2 mt-1"}>
                                                                                <label
                                                                                    className={`font-size-16 ${mouseHover === index ? 'font-color-blue' : 'font-color-neutral-grey-2'} font-weight-305 `}>{eachPosition.job_type}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={"bg-white rounded-circle job-container-icon p-md-3 p-2"}>
                                                                            <Icon.ChevronRight size={24}
                                                                                               color={`${mouseHover === index ? '#FF6F3C' : '#081250'}`}/>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                        {/*<Accordion.Item eventKey="0" className={"bb-1-light-brown"}>*/}
                                        {/*    <Accordion.Header className={"py-4"}>Civil</Accordion.Header>*/}
                                        {/*    <Accordion.Body className={"py-0"}>*/}
                                        {/*        <div className={"row row-gap-md-5 row-gap-3 mb-4 pb-md-2"}>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-md-2 mt-1"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Link to={"/job-application"}>*/}
                                        {/*                            <Icon.ChevronRight color={'#081250'} />*/}
                                        {/*                        </Link>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Accordion.Body>*/}
                                        {/*</Accordion.Item>*/}
                                        {/*<Accordion.Item eventKey="1" className={'bb-1-light-brown'}>*/}
                                        {/*    <Accordion.Header className={"py-4"}>Graduate - Civil</Accordion.Header>*/}
                                        {/*    <Accordion.Body className={" py-0"}>*/}
                                        {/*        <div className={"row row-gap-md-5 row-gap-3 mb-4 pb-md-2"}>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-md-2 mt-1"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Accordion.Body>*/}
                                        {/*</Accordion.Item>*/}
                                        {/*<Accordion.Item eventKey="2" className={"bb-1-light-brown"}>*/}
                                        {/*    <Accordion.Header className={"py-4"}>Diploma- Civil</Accordion.Header>*/}
                                        {/*    <Accordion.Body className={"py-0 "}>*/}
                                        {/*        <div className={"row row-gap-md-5 row-gap-3 mb-4 pb-md-2"}>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-md-2 mt-1"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Accordion.Body>*/}
                                        {/*</Accordion.Item>*/}
                                        {/*<Accordion.Item eventKey="3" className={'bb-1-light-brown'}>*/}
                                        {/*    <Accordion.Header>Site</Accordion.Header>*/}
                                        {/*    <Accordion.Body className={"py-0"}>*/}
                                        {/*        <div className={"row row-gap-md-5 row-gap-3 mb-4 pb-md-2"}>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-md-2 mt-1"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Accordion.Body>*/}
                                        {/*</Accordion.Item>*/}
                                        {/*<Accordion.Item eventKey="4" className={"bb-1-light-brown"}>*/}
                                        {/*    <Accordion.Header className={"py-4"}>Office</Accordion.Header>*/}
                                        {/*    <Accordion.Body className={"py-0"}>*/}
                                        {/*        <div className={"row row-gap-md-5 row-gap-3 mb-4 pb-md-2"}>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-md-2 mt-1"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className={"col-12 b-1-light-brown br-16 p-md-4 p-3"}>*/}
                                        {/*                <div*/}
                                        {/*                    className={"w-100 d-flex justify-content-between align-items-center p-2"}>*/}
                                        {/*                    <div>*/}
                                        {/*                        <label*/}
                                        {/*                            className={"font-size-18 font-weight-315 font-color-blue"}>Project*/}
                                        {/*                            Manager</label>*/}
                                        {/*                        <div className={"mt-2"}>*/}
                                        {/*                            <label*/}
                                        {/*                                className={"font-size-16 font-weight-305 font-color-neutral-grey-2"}>Full*/}
                                        {/*                                time</label>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div>*/}
                                        {/*                        <Icon.ChevronRight color={'#081250'}/>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </Accordion.Body>*/}
                                        {/*</Accordion.Item>*/}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default Careers;
