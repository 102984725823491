import NavbarPage from "../Navbar/navbarPage";
import * as Icon from 'react-feather';
import FooterPage from "../Footer/footerPage";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Mousewheel} from "swiper/modules";
import 'swiper/css';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import projectsData from '../Models/projects.json';
import {TailSpin} from "react-loader-spinner";

const Home = () => {

    const [bestProjects, setBestProjects] = useState([]);

    const [loadingButton, setLoadingButton] = useState(false);
    const [showMsg, setShowMsg] = useState('')

    const handleSubmitButton = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('contact-form');
        const data = new FormData(form);
        console.log(data)
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                setLoadingButton(false);
                setShowMsg('success');
                form.reset();
            }).catch(() => {
            setLoadingButton(false);
            setShowMsg('error')
            form.reset();
        });
    }

    useEffect(() => {
        const filterData = projectsData.filter(best => best.best_project === true);
        setBestProjects(filterData);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12 "}>

                    {/*  hero section  */}
                    <div className={"row bg-home-hero-container"}>
                        <div className={"col-12"}>

                            {/*  navbar  */}
                            <NavbarPage/>

                            <div className={"row"}>
                                <div className={"col-12 my-4 py-4 py-md-5"}></div>
                            </div>

                            <div className={"row justify-content-center mt-md-4"}>
                                <div className={"col-10 mt-1"}>
                                    <div className={"row d-flex justify-content-between py-5 my-4"}>
                                        <div className={"col-12 col-md-6"}>
                                            <h1 className={"font-size-96 main-heading font-weight-400 text-center font-zaf-renaissance font-color-neutral-white"}>Crafting
                                                Strong Foundations</h1>
                                        </div>
                                        <div className={"col-12 col-md-5 mt-4 pt-3 mt-md-0 pt-md-0 align-self-end"}>
                                            <div className={"mb-4"}>
                                                <img className={"img-fluid"}
                                                     src={"/images/home/hero-section-dotted-lines.png"}
                                                     alt={"dotted-line"}/>
                                            </div>
                                            <label className={"font-size-18 font-weight-305 font-color-neutral-white"}
                                                   style={{textAlign: 'justify'}}>
                                                Hyderabad’s top construction company: From residential to commercial
                                                buildings, bridges to rail tracks, we deliver guaranteed excellence. Our
                                                expertise covers planning, budgeting, and executing top-tier projects
                                                with guaranteed results.
                                            </label>
                                            <div
                                                className={"mt-4 pt-2 d-flex justify-content-center justify-content-md-start"}>
                                                <a href={"#contact"}>
                                                    <button type={"button"}
                                                            className={"bg-contact-us-btn font-sequel-sans-medium bg-orange border-0 font-size-16 font-weight-315 font-color-blue"}>
                                                        Contact Us
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  status  */}
                    <div className={"row py-2 justify-content-center"}>
                        <div className={"col-md-10 col-11 py-md-5 py-4 px-0"}>
                            <div className={"row gap-md-0 row-gap-3"}>
                                <div className={"col-6 col-md-3 d-flex align-items-stretch"}>
                                    <div className={"d-flex w-100 flex-column status-container"}>
                                        <h6 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-orange"}>98</h6>
                                        <div className={""}>
                                            <label
                                                className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-blue"}>Projects
                                                Completed</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-6 col-md-3 d-flex align-items-stretch"}>
                                    <div className={"d-flex w-100 flex-column status-container"}>
                                        <h6 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-orange"}>43</h6>
                                        <div className={""}>
                                            <label
                                                className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-blue"}>Years
                                                Of
                                                Experience</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-6 col-md-3 d-flex align-items-stretch"}>
                                    <div className={"d-flex w-100 flex-column status-container"}>
                                        <h6 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-orange"}>86</h6>
                                        <div className={"w-100"}>
                                            <label
                                                className={"font-size-18 status-heading font-weight-315 font-sequel-sans-medium font-color-blue"}>Happy
                                                Clients</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-6 col-md-3 d-flex align-items-stretch"}>
                                    <div className={"d-flex w-100 flex-column status-container"}>
                                        <h6 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-orange"}>100%</h6>
                                        <div className={""}>
                                            <label
                                                className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-blue"}>Client
                                                Satisfaction</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  about us  */}
                    <div className={"row justify-content-center"}>
                        <div className={"col-md-10 col-11 py-md-5 my-md-3 py-4 my-2 px-0"}>
                            <div className={"row bg-blue align-items-center about-us-container justify-content-md-between"}>
                                <div className={"col-md-5"}>
                                    <div
                                        className={"w-100 d-md-flex flex-column justify-content-center  align-items-center"}>
                                        <h2 className={"font-size-96 text-center main-heading w-100  font-weight-400 font-zaf-renaissance font-color-neutral-white"}>About
                                            Us</h2>

                                    </div>
                                </div>
                                <div className={"col-md-6"}>
                                    <div className={"d-flex w-100 flex-column mt-3 mt-md-0 justify-content-center"}>
                                        <label
                                            className={"font-color-neutral-white main-heading w-100 font-zaf-renaissance font-size-24 font-weight-400"}
                                            style={{textAlign: 'justify'}}>
                                            MVVS (M/s.M.V.V.SATYANARRAYANA) is a construction firm based in India
                                            that specializes in Infrastructure Projects. The company was founded in
                                            1984 as a partnership firm.
                                            MVVS has a presence across India, with offices in two cities and
                                            headquarters in Hyderabad.
                                        </label>
                                        <div className={"mt-4 pt-md-2"}>
                                            <Link to={"/about-us"}>
                                                <button type={"button"}
                                                        className={"bg-contact-us-btn font-sequel-sans-medium font-color-blue bg-orange border-0 font-size-16 font-weight-315"}>
                                                    Know More <Icon.ArrowRight color={"#081250"} size={19}/>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  best projects  */}
                    <div className={"row justify-content-center py-md-5"}>
                        <div className={"col-md-12 col-11 py-2 my-4"}>
                            {/* heading */}
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-6 col-12"}>
                                    <label
                                        className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                        Explore some of our best projects
                                    </label>
                                </div>
                                <div className={"col-md-4 col-12"}>
                                    <div className={"w-100 mt-4 mt-md-0 d-flex justify-content-md-end"}>
                                        <Link to={'/projects'}>
                                            <button type={"button"}
                                                    className={"bg-contact-us-btn font-sequel-sans-medium font-color-blue bg-orange border-0 font-size-16 font-weight-315"}>
                                                View All <Icon.ArrowRight color={"#081250"} size={19}/>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/*  project slides  */}

                            <div className={"row d-block d-md-none mt-4 "}>
                                {bestProjects.map((each, index) => (
                                    <>
                                        <div className={"col-12 p-0 d-flex justify-content-center"} key={index}>
                                            <Link to={`/project-details/${each.project_slug}`}>
                                                <div className={"w-100 best-project-container"}>
                                                    <div className={"mb-1"}>
                                                        <img className={"img-fluid w-100 br-8"}
                                                             src={`${each.project_card_image}`}
                                                             alt={"project-image"}/>
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <label
                                                            className={"font-size-14 font-weight-305 font-color-orange"}>{each.project_tag}</label>
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <label
                                                            className={"font-size-24 font-weight-400 font-zaf-renaissance font-color-blue"}>{each.project_name}</label>
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <label
                                                            className={"font-size-16 font-weight-305 font-color-blue"}>{each.project_location}</label>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        {index < bestProjects.length - 1 && (
                                            <div className={"col-12 mt-4 pt-2"}></div>)}
                                    </>

                                ))}
                            </div>

                            <div className={"row justify-content-center  best-projects-details-container"}>
                                <div className={"col-md-10 d-md-flex d-none mt-md-4 pt-md-3"}>
                                    <Swiper
                                        className=" w-100 overflow-visible justify-content-end"
                                        parallax={true}
                                        spaceBetween={30}
                                        slidesPerView={3}
                                        speed={1000}
                                        navigation={false}
                                        freeMode={true}
                                        modules={[Mousewheel]}
                                        mousewheel={{
                                            releaseOnEdges: true,
                                            eventsTarged: '.best-projects-details-container' // target the swiper container for mousewheel events
                                        }}
                                    >
                                        {bestProjects.map((eachProject, index) => (
                                            <SwiperSlide className={"d-flex align-items-stretch"} key={index}>
                                                <Link to={`/project-details/${eachProject.project_slug}`}
                                                      className={"w-100"}>
                                                    <div
                                                        className={"w-100 best-project-container d-flex flex-column align-items-stretch"}>
                                                        <div
                                                            className={"mb-1 image-container overflow-hidden position-relative services-button br-16"}>
                                                            <img className={"img-fluid w-100"}
                                                                 src={eachProject.project_card_image}
                                                                 alt={"project-image"}/>
                                                        </div>
                                                        <div className={"mt-2"}>
                                                            <label
                                                                className={"font-size-14 font-weight-305 font-color-orange"}>{eachProject.project_tag}</label>
                                                        </div>
                                                        <div className={"mt-2"}>
                                                            <label
                                                                className={"font-size-24 font-weight-400 font-zaf-renaissance text-truncate w-100 line-height-32 font-color-blue"}>{eachProject.project_name}</label>
                                                        </div>
                                                        <div className={"mt-2"}>
                                                            <label
                                                                className={"font-size-16 font-weight-305 font-color-blue"}>{eachProject.project_client}</label>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/*  services  */}
                    <div className={"row py-md-5 bg-blue"}>
                        <div className={"col-12 py-2 my-4 p-0"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-10 col-11"}>
                                    <div className={"row justify-content-between"}>
                                        <div className={"col-md-6"}>
                                            <div className={"d-flex flex-column justify-content-start"}>
                                                <label
                                                    className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-orange"}>Expertise</label>
                                                <div>
                                                    <label
                                                        className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-brand-light"}>Our
                                                        Services</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 ps-md-4 mt-4 mt-md-0"}>
                                            <label className={"font-size-16 font-weight-315 font-color-neutral-grey-3"}
                                                   style={{textAlign: 'justify'}}>
                                                Any Structure. Any Shape. Any Size. We’ve Got Your Back. MVVS is a
                                                trusted name in the construction industry. We offer construction
                                                services in diverse areas, including roadworks, building construction,
                                                bridge engineering, and track work.
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"row mt-4 pt-md-3"}>
                                        <div className={"col-md-3 col-12 d-flex"}>
                                            <div className={"w-100"}>
                                                <div className={""}>
                                                    <img className={"img-fluid w-100 br-8"}
                                                         src={"/images/home/hospital-building-image.png"}
                                                         alt={"project-image"}/>
                                                </div>
                                                <div className={"mt-md-4 mt-3"}>
                                                    <label
                                                        className={"font-size-24 font-weight-400 font-zaf-renaissance font-color-brand-light"}>Buildings</label>
                                                </div>
                                                <div className={"mt-md-2 mt-1"}>
                                                    <label
                                                        className={"font-size-16 font-weight-305 font-color-brand-light font-opacity-60"}
                                                        style={{textAlign: 'justify'}}>
                                                        Transform your vision into reality with our expert, affordable
                                                        building construction services for residential and commercial
                                                        projects.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-3 mt-4 pt-2 mt-md-0 pt-md-0 d-flex"}>
                                            <div className={"w-100"}>
                                                <div className={""}>
                                                    <img className={"img-fluid w-100 br-8"}
                                                         src={"/images/home/bridge-image.png"}
                                                         alt={"project-image"}/>
                                                </div>
                                                <div className={"mt-md-4 mt-3"}>
                                                    <label
                                                        className={"font-size-24 font-weight-400 font-zaf-renaissance font-color-brand-light"}>Bridges</label>
                                                </div>
                                                <div className={"mt-md-2 mt-1"}>
                                                    <label
                                                        className={"font-size-16 font-weight-305 font-color-brand-light font-opacity-60"}
                                                        style={{textAlign: 'justify'}}>
                                                        Build strong and durable bridges with our expert construction
                                                        services, ensuring safety and longevity.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-3 mt-4 pt-2 mt-md-0 pt-md-0 d-flex"}>
                                            <div className={"w-100"}>
                                                <div className={""}>
                                                    <img className={"img-fluid w-100 br-8"}
                                                         src={"/images/home/road-works-image.png"}
                                                         alt={"project-image"}/>
                                                </div>
                                                <div className={"mt-md-4 mt-3"}>
                                                    <label
                                                        className={"font-size-24 font-weight-400 font-zaf-renaissance font-color-brand-light"}>Road
                                                        Works</label>
                                                </div>
                                                <div className={"mt-md-2 mt-1"}>
                                                    <label
                                                        className={"font-size-16 font-weight-305 font-color-brand-light font-opacity-60"}
                                                        style={{textAlign: 'justify'}}>
                                                        Transform your infrastructure with our professional roadwork
                                                        services, delivering quality roads for smooth journeys.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-3 mt-4 pt-2 mt-md-0 pt-md-0 d-flex"}>
                                            <div className={"w-100 "}>
                                                <div className={""}>
                                                    <img className={"img-fluid w-100 br-8"}
                                                         src={"/images/home/trackers-image.png"}
                                                         alt={"project-image"}/>
                                                </div>
                                                <div className={"mt-md-4 mt-3"}>
                                                    <label
                                                        className={"font-size-24 font-weight-400 font-zaf-renaissance font-color-brand-light"}>Track
                                                        Works</label>
                                                </div>
                                                <div className={"mt-md-2 mt-1 w-100"}>
                                                    <label
                                                        className={"font-size-16 font-weight-305 font-color-brand-light font-opacity-60"}
                                                        style={{textAlign: 'justify'}}>
                                                        Enhance rail network with our reliable track work services,
                                                        ensuring safety and operational efficiency.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Have a project in mind?  */}
                    <div className={"row bg-promotion-container"}>
                        <div className={"col-12"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-8 py-md-5 py-4 my-md-5 "}>
                                    <div className={"row justify-content-center"}>
                                        <div className={"col-md-10 col-11"}>
                                            <label
                                                className={"font-size-18 font-sequel-sans-medium font-weight-315 font-color-orange"}>
                                                Have a project in mind?
                                            </label>
                                            <div className={"mt-md-4 mt-2"}>
                                                <h3 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                                    We'll work closely with you to understand your needs and create a
                                                    custom
                                                    design that reflects your style and values.
                                                </h3>
                                            </div>
                                            <div className={"mt-4 mt-md-3"}>
                                                <a href={"#contact"}>
                                                    <button type={"button"}
                                                            className={"bg-contact-us-btn font-sequel-sans-medium border-0 bg-orange font-color-blue font-size-16 font-weight-315"}>
                                                        Contact Us
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-4 d-md-block d-none p-0"}>
                                    <img className={"img-fluid w-100 h-100"}
                                         src={"/images/home/night-view-bridge-image.png"}
                                         alt={"night-view-bridge-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  contact us  */}
                    <div className={"row"}>
                        <div className={"col-12 py-4 my-2 d-md-flex d-none"}></div>
                    </div>
                    <div className={"row "}>
                        <div className={"col-12 py-md-5 my-md-3 py-4 mt-2"}>
                            <div className={"row justify-content-center"} id={"contact"}>
                                <div className={"col-md-10 col-11"}>
                                    <div className={"w-100 d-flex justify-content-center"}>
                                        <label
                                            className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Contact
                                            Us</label>
                                    </div>
                                    <form className={"row mt-4 justify-content-center pt-md-3"} id={"contact-form"}
                                          onSubmit={handleSubmitButton} method={"POST"}
                                          action={"https://script.google.com/macros/s/AKfycbx_ofLL7ZSHx-opaKlB4nDyAuloOkIEZMsJfhhHBAVp7MieMgegQ09vwLWm5LnIMmsUNA/exec"}>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>First
                                                        Name and
                                                        Last Name</label>
                                                </div>
                                                <div className={"col-md-3 col-12 ps-md-0"}>
                                                    <input type={"text"} required={true} name={"FirstName"}
                                                           className={"form-control"}
                                                           placeholder={"Enter your first name"}/>
                                                </div>
                                                <div className={"col-md-3 col-12 ps-md-0"}>
                                                    <input type={"text"} required={true} name={"LastName"}
                                                           className={"form-control"}
                                                           placeholder={"Enter your last name"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Email
                                                        Address</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"email"} required={true} name={"Email"}
                                                           className={"form-control"}
                                                           placeholder={"Enter your mail id"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Business
                                                        Name</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"text"} name={"BusinessName"}
                                                           className={"form-control"}
                                                           placeholder={"Enter your business name"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12 "}>
                                                    <label
                                                        className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Phone
                                                        Number</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"number"} required={true} name={"PhoneNumber"}
                                                           className={"form-control"}
                                                           placeholder={"Enter your phone number"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Subject</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"text"} name={"Subject"} className={"form-control"}
                                                           placeholder={"Enter your project needs"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Message</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <textarea type={"text"} rows={4} name={"Message"}
                                                              className={"form-control"}
                                                              placeholder={"Tell us about the project"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 pt-md-4 pb-4 px-0"}>
                                            <div className={"row justify-content-end"}>
                                                <div className={"col-12 col-md-6"}>
                                                    <div
                                                        className={"row justify-content-center justify-content-md-start"}>
                                                        <button type={"submit"} disabled={loadingButton}
                                                                className={"bg-orange col-11 col-md-4 border-0 font-sequel-sans-medium bg-contact-us-btn font-color-blue font-size-16 font-weight-315"}>
                                                            {loadingButton ? (<div
                                                                    className={"d-inline-flex align-items-center gap-2"}>
                                                                    <TailSpin color={'#081250'} width={24} height={24}/>
                                                                    Submit
                                                                </div>) :
                                                                ('Submit')}
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 pb-md-4 pb-3"}>
                                            <div className={"row justify-content-end"}>
                                                <div className={"col-md-6"}>
                                                    {showMsg === 'success' && (
                                                        <div className={"w-100 bg-green-light br-8 p-md-4 p-3"}>
                                                            <label
                                                                className={"font-size-18 font-weight-315 font-color-green font-sequel-sans-medium"}>Thank
                                                                you for contacting us! Your message has been
                                                                successfully
                                                                received. We will get back to you shortly.</label>
                                                        </div>
                                                    )}
                                                    {showMsg === 'error' && (
                                                        <div className={"w-100 bg-light-red br-8 p-md-4 p-3"}>
                                                            <label
                                                                className={"font-size-18 font-weight-315 text-danger font-sequel-sans-medium"}>Thank
                                                                Something went wrong while submitting your message.
                                                                Please try
                                                                again later. Thank you for your understanding.
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default Home;
