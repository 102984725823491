import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import careersData from '../Models/careers.json';
import * as Icon from 'react-feather';
import {TailSpin} from "react-loader-spinner";

const JobApplication = () => {
    const params = useParams();
    const id = params.id;
    // console.log(params.id);

    const [jobData, setJobData] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [showMsg, setShowMsg] = useState('')
    const [resume, setResume] = useState(null);
    const [resumeName, setResumeName] = useState('');
    // const[fileType, setFileType] = useState('');
    // const [resumePdf, setResumePdf] = useState(null);
    const fileInputRef = useRef(null);

    // Function to handle the file input click
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const uploadResume = (e) => {
        const file = e.target.files[0];
        console.log(file.name);
        setResume(file);
        setResumeName(file.name);
        // setFileType(file.type);
    }

    const handleSubmitButton = async (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('job-form');
        const data = new FormData(form);

        if (resume) {
            const reader = new FileReader();
            console.log(reader);
            reader.onloadend = async () => {
                console.log(reader);
                const base64Resume = reader.result.split(",")[1];
                // setResumePdf(base64Resume);

                // Append the necessary data to FormData
                data.append("Resume", resume.name);
                data.append("ResumePdf", base64Resume); // Use the base64 string directly here
                data.append("FileType", resume.type);
                data.append("AppliedJob", id);

                try {
                    const response = await fetch(e.target.action, {
                        method: "POST",
                        body: data,
                    });

                    if (response.ok) {
                        setLoadingButton(false);
                        setShowMsg('success');
                        form.reset(); // Reset form on success
                    } else {
                        throw new Error("Submission failed");
                    }
                } catch (error) {
                    setLoadingButton(false);
                    setShowMsg('error')
                    form.reset();
                } finally {
                    setLoadingButton(false); // Ensure loading state is cleared
                }
            }
            // console.log("resumePdf", resumePdf);
            // data.append("Resume", resumeName);
            // await data.append("ResumePdf", resumePdf);
            // data.append("FileType", fileType);

            // const action = 'https://script.google.com/macros/s/AKfycbztLUgCbvWjT22nBlh6aBOrXKwZDZJFTtR6qqKbMfNx5Snw0FxfQlMoSFHZHdrxAINc/exec';

            // fetch(action, {
            //     method: 'POST',
            //     body: data,
            //
            // })
            //     .then(() => {
            //         setLoadingButton(false);
            //         setShowMsg('success');
            //         form.reset();
            //     }).catch(() => {
            //     setLoadingButton(false);
            //     setShowMsg('error')
            //     form.reset();
            // });

            reader.readAsDataURL(resume);
        }
    }

    useEffect(() => {
        const filterData = careersData.map(each =>
            each.job_positions.find(job => job.job_slug === id)
        ).filter(Boolean);
        // console.log(filterData)
        setJobData(filterData)

        window.scrollTo(0, 0);
    }, [id]);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12 other-page-nav-color"}>
                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  job details  */}
                    <div className={"row justify-content-center my-md-5 my-2"}>
                        {jobData.map((each, index) => (
                            <div className={"col-12 col-md-10 my-4"} key={index}>
                                <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                    <label
                                        className={"font-size-56 font-zaf-renaissance font-weight-400 font-color-blue"}>{each.job_role}</label>
                                </div>

                                <div className={"row my-md-5 py-4"}>
                                    <div className={"col-12 my-md-2"}>
                                        {each.job_description.map((job, index) => (
                                            <>
                                                <div className={"row justify-content-between row-gap-md-4 row-gap-2"}
                                                     key={index}>
                                                    <div className={"col-md-4 col-12"}>
                                                        <label
                                                            className={"font-size-32 font-weight-400 font-zaf-renaissance font-color-blue"}>{job.description_heading}</label>
                                                    </div>
                                                    <div className={"col-md-8 col-12"}>
                                                        <label
                                                            className={"font-size-18 font-weight-305 font-color-blue"}>{job.description}</label>
                                                    </div>
                                                </div>
                                                {index < each.job_description.length - 1 ? (
                                                    <div className={"row mt-4 pt-md-3 pt-2"}>
                                                        <div className={"col-12"}></div>
                                                    </div>
                                                ) : ('')}
                                            </>
                                        ))}

                                        {/*<div*/}
                                        {/*    className={"row justify-content-between mt-4 pt-md-3 pt-2 row-gap-md-4 row-gap-2"}>*/}
                                        {/*    <div className={"col-md-4 col-12"}>*/}
                                        {/*        <label className={"font-size-32 font-weight-400 font-color-blue"}>Job*/}
                                        {/*            Overview</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className={"col-md-8 col-12"}>*/}
                                        {/*        <label className={"font-size-18 font-weight-305 font-color-blue"}>MVVS*/}
                                        {/*            is*/}
                                        {/*            fabulous in bridge engineering, whether it is a waterway*/}
                                        {/*            bridge, road over bridge, road under bridge, foot over bridge. Its*/}
                                        {/*            team*/}
                                        {/*            is committed to following all drawings and specifications applicable*/}
                                        {/*            to*/}
                                        {/*            the bridge, including foundations, substructures, and*/}
                                        {/*            superstructures.*/}
                                        {/*            MVVS has executed the maximum span of a road over Bridge of 2 X*/}
                                        {/*            42.00 m*/}
                                        {/*            Composite girder, Road under bridge of 2 X 11.40 m as RCC BOX, Foot*/}
                                        {/*            over*/}
                                        {/*            bridge of 220 X 5.5 m and many more.</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className={"row justify-content-between mt-4 pt-md-3 pt-2 row-gap-md-4 row-gap-2"}>*/}
                                        {/*    <div className={"col-md-4 col-12"}>*/}
                                        {/*        <label*/}
                                        {/*            className={"font-size-32 font-weight-400 font-color-blue"}>Responsibilities</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className={"col-md-8 col-12"}>*/}
                                        {/*        <label className={"font-size-18 font-weight-305 font-color-blue"}>MVVS*/}
                                        {/*            is*/}
                                        {/*            fabulous in bridge engineering, whether it is a waterway*/}
                                        {/*            bridge, road over bridge, road under bridge, foot over bridge. Its*/}
                                        {/*            team*/}
                                        {/*            is committed to following all drawings and specifications applicable*/}
                                        {/*            to*/}
                                        {/*            the bridge, including foundations, substructures, and*/}
                                        {/*            superstructures.*/}
                                        {/*            MVVS has executed the maximum span of a road over Bridge of 2 X*/}
                                        {/*            42.00 m*/}
                                        {/*            Composite girder, Road under bridge of 2 X 11.40 m as RCC BOX, Foot*/}
                                        {/*            over*/}
                                        {/*            bridge of 220 X 5.5 m and many more.</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className={"row justify-content-between mt-4 pt-md-3 pt-2 row-gap-md-4 row-gap-2"}>*/}
                                        {/*    <div className={"col-md-4 col-12"}>*/}
                                        {/*        <label*/}
                                        {/*            className={"font-size-32 font-weight-400 font-color-blue"}>Benefits</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className={"col-md-8 col-12"}>*/}
                                        {/*        <label className={"font-size-18 font-weight-305 font-color-blue"}>MVVS*/}
                                        {/*            is*/}
                                        {/*            fabulous in bridge engineering, whether it is a waterway*/}
                                        {/*            bridge, road over bridge, road under bridge, foot over bridge. Its*/}
                                        {/*            team*/}
                                        {/*            is committed to following all drawings and specifications applicable*/}
                                        {/*            to*/}
                                        {/*            the bridge, including foundations, substructures, and*/}
                                        {/*            superstructures.*/}
                                        {/*            MVVS has executed the maximum span of a road over Bridge of 2 X*/}
                                        {/*            42.00 m*/}
                                        {/*            Composite girder, Road under bridge of 2 X 11.40 m as RCC BOX, Foot*/}
                                        {/*            over*/}
                                        {/*            bridge of 220 X 5.5 m and many more.</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                                {/*  apply form  */}
                                <div className={"row mt-4 pt-3 mt-md-0 pt-md-0"}>
                                    <div className={"col-12"}>
                                        <div className={"w-100 d-flex justify-content-center"}>
                                            <label
                                                className={"font-size-32 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                                Apply For This Job
                                            </label>
                                        </div>

                                        <form className={"row mt-md-4 justify-content-center pt-md-3 "} id={"job-form"}
                                              onSubmit={handleSubmitButton} method={"POST"}
                                              action={"https://script.google.com/macros/s/AKfycbyk69BOi7J7mPh8iLVtlEbYhR6MW0lKRZnMaZCw6rXkh0lXJZxNowmF5MgJesDhMjw1aQ/exec"}>
                                            <div className={"col-11 px-0 form-field-border"}>
                                                <div
                                                    className={"row justify-content-between align-items-center py-4 gap-md-0 gap-3"}>
                                                    <div className={"col-md-6 col-12"}>
                                                        <label
                                                            className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>First
                                                            Name and
                                                            Last Name</label>
                                                    </div>
                                                    <div className={"col-md-3 col-12 ps-md-0"}>
                                                        <input type={"text"} name={"FirstName"}
                                                               className={"form-control"} required={true}
                                                               placeholder={"Enter your first name"}/>
                                                    </div>
                                                    <div className={"col-md-3 col-12 ps-md-0"}>
                                                        <input type={"text"} name={"LastName"}
                                                               className={"form-control"} required={true}
                                                               placeholder={"Enter your last name"}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-11 px-0 form-field-border"}>
                                                <div
                                                    className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                    <div className={"col-md-6 col-12"}>
                                                        <label
                                                            className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Email
                                                            Address</label>
                                                    </div>
                                                    <div className={"col-md-6 col-12 ps-md-0"}>
                                                        <input type={"email"} name={"Email"} required={true} className={"form-control"}
                                                               placeholder={"Enter your mail id"}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-11 px-0"}>
                                                <div
                                                    className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                    <div className={"col-md-6 col-12"}>
                                                        <label
                                                            className={"font-size-16 font-sequel-sans-medium font-weight-315 font-color-blue"}>Resume</label>
                                                    </div>
                                                    <div className="col-md-6 col-12 px-0">
                                                        <div className="w-100 form-control custom-file-upload">
                                                            <input type={'file'} name={"Resume"}
                                                                   accept="application/pdf"
                                                                   className={"border-0 file-input"}
                                                                   ref={fileInputRef}
                                                                   onChange={(e) => uploadResume(e)}/>
                                                            <label
                                                                className={"font-size-18 font-weight-305 font-color-blue"}>{resumeName}</label>
                                                            <button type={"button"} onClick={handleButtonClick}
                                                                    className="custom-button d-none d-md-block">
                                                                Browse
                                                            </button>
                                                            <button type={"button"}
                                                                    onClick={handleButtonClick}
                                                                    className="custom-button d-flex d-md-none">
                                                                <Icon.Upload/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-md-12 col-11 pb-md-4 pb-3"}>
                                                <div className={"row justify-content-end"}>
                                                    <div className={"col-12 col-md-6"}>
                                                        <div className={"row"}>
                                                            <button type={"submit"} disabled={loadingButton}
                                                                    className={"bg-orange font-sequel-sans-medium col-12 col-md-6 border-0 bg-contact-us-btn font-color-blue font-size-16 font-weight-315"}>
                                                                {loadingButton ? (<div
                                                                        className={"d-inline-flex align-items-center gap-2"}>
                                                                        <TailSpin color={'#081250'} width={24} height={24}/>
                                                                        Submit Application
                                                                    </div>) :
                                                                    ('Submit Application')}
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-12 pb-md-4 pb-3"}>
                                                <div className={"row justify-content-end"}>
                                                    <div className={"col-md-6"}>
                                                        {showMsg === 'success' && (
                                                            <div className={"w-100 bg-green-light br-8 p-md-4 p-3"}>
                                                                <label
                                                                    className={"font-size-18 font-weight-315 font-color-green font-sequel-sans-medium"}>Thank
                                                                    you for your interest in joining our team! We have
                                                                    received your application successfully. You can
                                                                    expect to hear from us soon.</label>
                                                            </div>
                                                        )}
                                                        {showMsg === 'error' && (
                                                            <div className={"w-100 bg-light-red br-8 p-md-4 p-3"}>
                                                                <label
                                                                    className={"font-size-18 font-weight-315 text-danger font-sequel-sans-medium"}>Thank
                                                                    Something went wrong while submitting your
                                                                    application. Please try
                                                                    again later. Thank you for your understanding.
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        ))}

                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default JobApplication;
