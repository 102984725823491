import './App.css';
import './components/Styles/scss/main.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Team from "./components/Team/Team";
import Services from "./components/Services/services";
import Projects from "./components/Projects/projects";
import ProjectDetails from "./components/ProjectDetails/projectDetails";
import Home from "./components/HomePage/home";
import Experience from "./components/Experience/experience";
import ContactUs from "./components/ContactUs/contactUs";
import Careers from "./components/Careers/careers";
import Achievements from "./components/Achievements/achievements";
import AboutUs from "./components/AboutUs/aboutUs";
import Financial from "./components/Financials/financial";
import JobApplication from "./components/JobApplication/jobApplication";

function App() {
    return (
        <div className="">
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/team'} element={<Team/>}/>
                    <Route path={'/services'} element={<Services/>}/>
                    <Route path={'/projects'} element={<Projects/>}/>
                    <Route path={'/project-details/:id'} element={<ProjectDetails/>}/>
                    <Route path={'/experience'} element={<Experience/>}/>
                    <Route path={'/contact-us'} element={<ContactUs/>}/>
                    <Route path={'/careers'} element={<Careers/>}/>
                    <Route path={'/achievements'} element={<Achievements/>}/>
                    <Route path={'/about-us'} element={<AboutUs/>}/>
                    <Route path={'/financial'} element={<Financial/>}/>
                    <Route path={'/job-application/:id'} element={<JobApplication/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
