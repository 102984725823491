import NavbarPage from "../Navbar/navbarPage";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import * as Icon from 'react-feather';
import {useEffect, useState} from "react";
import FooterPage from "../Footer/footerPage";
import experiencesData from '../Models/experiences.json';

const Experience = () => {

    const [experienceData, setExperienceData] = useState([]);

    useEffect(() => {
        setExperienceData(experiencesData);
        window.scrollTo(0,0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12 other-page-nav-color"}>

                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    <div className={"row justify-content-center my-3 my-md-5"}>
                        <div className={"col-12 col-md-11 my-3"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Experience</label>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 mt-4 pt-md-3"}>
                                    <VerticalTimeline intersectionObserverProps={{rootMargin: '0px 0px 40px 0px'} } lineColor={"#CCCCCC"} className={""}>
                                        {experienceData.map((each) => (
                                            <VerticalTimelineElement
                                                key={each.id}
                                                className="vertical-timeline-element--work"
                                                icon={<Icon.Circle fill={'#FF6F3C'}/>}
                                                contentArrowStyle={{ borderRight: 'none' }}
                                                iconStyle={{background: '#fff', color: '#fff', border: 'none'}}
                                                contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}
                                            >
                                                <label className="vertical-timeline-element-title font-sequel-sans-medium font-size-18 font-color-orange font-weight-315">{each.date}</label>
                                                <div className={"mt-2 mb-3"}>
                                                    <label
                                                        className="vertical-timeline-element-subtitle font-zaf-renaissance font-size-24 font-color-blue font-weight-400">{each.card_heading}</label>
                                                </div>
                                                <label className={"font-size-18 font-color-neutral-grey-1 font-sequel-sans-medium font-weight-315"}>
                                                    {each.card_sub_title}
                                                </label>
                                                <div className={"mt-3"}>
                                                    <label className={"font-size-16 font-weight-305 font-color-blue"} style={{textAlign: 'justify'}}>
                                                        {each.card_description}
                                                    </label>
                                                </div>
                                            </VerticalTimelineElement>
                                        ))}
                                        {/*<VerticalTimelineElement*/}
                                        {/*    className="vertical-timeline-element--work"*/}
                                        {/*    icon={<Icon.Circle fill={'#FF6F3C'}/>}*/}
                                        {/*    contentArrowStyle={{ borderRight: 'none' }}*/}
                                        {/*    iconStyle={{background: '#fff', color: '#fff', border: 'none'}}*/}
                                        {/*    contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}*/}
                                        {/*>*/}
                                        {/*    <label className="vertical-timeline-element-title font-size-18 font-color-orange font-weight-315">October 2018</label>*/}
                                        {/*    <div className={"mt-2 mb-3"}>*/}
                                        {/*        <label*/}
                                        {/*            className="vertical-timeline-element-subtitle font-size-24 font-color-blue font-weight-400">Regadapalli*/}
                                        {/*            -ROB- PSC*/}
                                        {/*            steel girder</label>*/}
                                        {/*    </div>*/}
                                        {/*    <label className={"font-size-18 font-color-neutral-grey-1 font-weight-315"}>*/}
                                        {/*        Regadapalli and Vanganur Stations (Andra Pradesh)*/}
                                        {/*    </label>*/}
                                        {/*    <div className={"mt-3"}>*/}
                                        {/*        <label className={"font-size-16 font-weight-305 font-color-blue"}>*/}
                                        {/*            Guntakal Division - Construction of Road Over Bridge as*/}
                                        {/*            1x28.284m(Sk)/*/}
                                        {/*            20.0m(Sq) PSC Girder + 1x42.426m(Sk)/30.0m(Sq) Composite Girder*/}
                                        {/*            (steel*/}
                                        {/*            'I' girder with RCC desk slab) + 1x28.284m(Sk)/ 20.0m(Sq) PSC Girder*/}
                                        {/*            on*/}
                                        {/*            pile foundation to cater 2 lane ROB at Km:347,33-35 between*/}
                                        {/*            Regadapalli*/}
                                        {/*            and Vanganur stations (Deposit work)*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</VerticalTimelineElement>*/}
                                        {/*<VerticalTimelineElement*/}
                                        {/*    className="vertical-timeline-element--work"*/}
                                        {/*    icon={<Icon.Circle fill={'#FF6F3C'}/>}*/}
                                        {/*    contentArrowStyle={{ borderRight: 'none' }}*/}
                                        {/*    iconStyle={{background: '#fff', color: '#fff', border: 'none'}}*/}
                                        {/*    contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}*/}
                                        {/*>*/}
                                        {/*    <label className="vertical-timeline-element-title font-size-18 font-color-orange font-weight-315">October 2018</label>*/}
                                        {/*    <div className={"mt-2 mb-3"}>*/}
                                        {/*        <label*/}
                                        {/*            className="vertical-timeline-element-subtitle font-size-24 font-color-blue font-weight-400">Regadapalli*/}
                                        {/*            -ROB- PSC*/}
                                        {/*            steel girder</label>*/}
                                        {/*    </div>*/}
                                        {/*    <label className={"font-size-18 font-color-neutral-grey-1 font-weight-315"}>*/}
                                        {/*        Regadapalli and Vanganur Stations (Andra Pradesh)*/}
                                        {/*    </label>*/}
                                        {/*    <div className={"mt-3"}>*/}
                                        {/*        <label className={"font-size-16 font-weight-305 font-color-blue"}>*/}
                                        {/*            Guntakal Division - Construction of Road Over Bridge as*/}
                                        {/*            1x28.284m(Sk)/*/}
                                        {/*            20.0m(Sq) PSC Girder + 1x42.426m(Sk)/30.0m(Sq) Composite Girder*/}
                                        {/*            (steel*/}
                                        {/*            'I' girder with RCC desk slab) + 1x28.284m(Sk)/ 20.0m(Sq) PSC Girder*/}
                                        {/*            on*/}
                                        {/*            pile foundation to cater 2 lane ROB at Km:347,33-35 between*/}
                                        {/*            Regadapalli*/}
                                        {/*            and Vanganur stations (Deposit work)*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</VerticalTimelineElement>*/}
                                        {/*<VerticalTimelineElement*/}
                                        {/*    className="vertical-timeline-element--work"*/}
                                        {/*    icon={<Icon.Circle fill={'#FF6F3C'}/>}*/}
                                        {/*    contentArrowStyle={{ borderRight: 'none' }}*/}
                                        {/*    iconStyle={{background: '#fff', color: '#fff', border: 'none'}}*/}
                                        {/*    contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}*/}
                                        {/*>*/}
                                        {/*    <label className="vertical-timeline-element-title font-size-18 font-color-orange font-weight-315">October 2018</label>*/}
                                        {/*    <div className={"mt-2 mb-3"}>*/}
                                        {/*        <label*/}
                                        {/*            className="vertical-timeline-element-subtitle font-size-24 font-color-blue font-weight-400">Regadapalli*/}
                                        {/*            -ROB- PSC*/}
                                        {/*            steel girder</label>*/}
                                        {/*    </div>*/}
                                        {/*    <label className={"font-size-18 font-color-neutral-grey-1 font-weight-315"}>*/}
                                        {/*        Regadapalli and Vanganur Stations (Andra Pradesh)*/}
                                        {/*    </label>*/}
                                        {/*    <div className={"mt-3"}>*/}
                                        {/*        <label className={"font-size-16 font-weight-305 font-color-blue"}>*/}
                                        {/*            Guntakal Division - Construction of Road Over Bridge as*/}
                                        {/*            1x28.284m(Sk)/*/}
                                        {/*            20.0m(Sq) PSC Girder + 1x42.426m(Sk)/30.0m(Sq) Composite Girder*/}
                                        {/*            (steel*/}
                                        {/*            'I' girder with RCC desk slab) + 1x28.284m(Sk)/ 20.0m(Sq) PSC Girder*/}
                                        {/*            on*/}
                                        {/*            pile foundation to cater 2 lane ROB at Km:347,33-35 between*/}
                                        {/*            Regadapalli*/}
                                        {/*            and Vanganur stations (Deposit work)*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</VerticalTimelineElement>*/}
                                        {/*<VerticalTimelineElement*/}
                                        {/*    className="vertical-timeline-element--work"*/}
                                        {/*    icon={<Icon.Circle fill={'#FF6F3C'}/>}*/}
                                        {/*    contentArrowStyle={{ borderRight: 'none' }}*/}
                                        {/*    iconStyle={{background: '#fff', color: '#fff', border: 'none'}}*/}
                                        {/*    contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}*/}
                                        {/*>*/}
                                        {/*    <label className="vertical-timeline-element-title font-size-18 font-color-orange font-weight-315">October 2018</label>*/}
                                        {/*    <div className={"mt-2 mb-3"}>*/}
                                        {/*        <label*/}
                                        {/*            className="vertical-timeline-element-subtitle font-size-24 font-color-blue font-weight-400">Regadapalli*/}
                                        {/*            -ROB- PSC*/}
                                        {/*            steel girder</label>*/}
                                        {/*    </div>*/}
                                        {/*    <label className={"font-size-18 font-color-neutral-grey-1 font-weight-315"}>*/}
                                        {/*        Regadapalli and Vanganur Stations (Andra Pradesh)*/}
                                        {/*    </label>*/}
                                        {/*    <div className={"mt-3"}>*/}
                                        {/*        <label className={"font-size-16 font-weight-305 font-color-blue"}>*/}
                                        {/*            Guntakal Division - Construction of Road Over Bridge as*/}
                                        {/*            1x28.284m(Sk)/*/}
                                        {/*            20.0m(Sq) PSC Girder + 1x42.426m(Sk)/30.0m(Sq) Composite Girder*/}
                                        {/*            (steel*/}
                                        {/*            'I' girder with RCC desk slab) + 1x28.284m(Sk)/ 20.0m(Sq) PSC Girder*/}
                                        {/*            on*/}
                                        {/*            pile foundation to cater 2 lane ROB at Km:347,33-35 between*/}
                                        {/*            Regadapalli*/}
                                        {/*            and Vanganur stations (Deposit work)*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</VerticalTimelineElement>*/}
                                        {/*<VerticalTimelineElement*/}
                                        {/*    className="vertical-timeline-element--work"*/}
                                        {/*    icon={<Icon.Circle fill={'#FF6F3C'}/>}*/}
                                        {/*    contentArrowStyle={{ borderRight: 'none' }}*/}
                                        {/*    iconStyle={{background: '#fff', color: '#fff', border: 'none'}}*/}
                                        {/*    contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}*/}
                                        {/*>*/}
                                        {/*    <label className="vertical-timeline-element-title font-size-18 font-color-orange font-weight-315">October 2018</label>*/}
                                        {/*    <div className={"mt-2 mb-3"}>*/}
                                        {/*        <label*/}
                                        {/*            className="vertical-timeline-element-subtitle font-size-24 font-color-blue font-weight-400">Regadapalli*/}
                                        {/*            -ROB- PSC*/}
                                        {/*            steel girder</label>*/}
                                        {/*    </div>*/}
                                        {/*    <label className={"font-size-18 font-color-neutral-grey-1 font-weight-315"}>*/}
                                        {/*        Regadapalli and Vanganur Stations (Andra Pradesh)*/}
                                        {/*    </label>*/}
                                        {/*    <div className={"mt-3"}>*/}
                                        {/*        <label className={"font-size-16 font-weight-305 font-color-blue"}>*/}
                                        {/*            Guntakal Division - Construction of Road Over Bridge as*/}
                                        {/*            1x28.284m(Sk)/*/}
                                        {/*            20.0m(Sq) PSC Girder + 1x42.426m(Sk)/30.0m(Sq) Composite Girder*/}
                                        {/*            (steel*/}
                                        {/*            'I' girder with RCC desk slab) + 1x28.284m(Sk)/ 20.0m(Sq) PSC Girder*/}
                                        {/*            on*/}
                                        {/*            pile foundation to cater 2 lane ROB at Km:347,33-35 between*/}
                                        {/*            Regadapalli*/}
                                        {/*            and Vanganur stations (Deposit work)*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</VerticalTimelineElement>*/}
                                        {/*<VerticalTimelineElement*/}
                                        {/*    className="vertical-timeline-element--work"*/}
                                        {/*    icon={<Icon.Circle fill={'#FF6F3C'}/>}*/}
                                        {/*    contentArrowStyle={{ borderRight: 'none' }}*/}
                                        {/*    iconStyle={{background: '#fff', color: '#fff', border: 'none'}}*/}
                                        {/*    contentStyle={{backgroundColor: 'rgba(8, 18, 80, 0.05)', border:'1px solid rgba(8, 18, 80, 0.1)', borderRadius: '16px'}}*/}
                                        {/*>*/}
                                        {/*    <label className="vertical-timeline-element-title font-size-18 font-color-orange font-weight-315">October 2018</label>*/}
                                        {/*    <div className={"mt-2 mb-3"}>*/}
                                        {/*        <label*/}
                                        {/*            className="vertical-timeline-element-subtitle font-size-24 font-color-blue font-weight-400">Regadapalli*/}
                                        {/*            -ROB- PSC*/}
                                        {/*            steel girder</label>*/}
                                        {/*    </div>*/}
                                        {/*    <label className={"font-size-18 font-color-neutral-grey-1 font-weight-315"}>*/}
                                        {/*        Regadapalli and Vanganur Stations (Andra Pradesh)*/}
                                        {/*    </label>*/}
                                        {/*    <div className={"mt-3"}>*/}
                                        {/*        <label className={"font-size-16 font-weight-305 font-color-blue"}>*/}
                                        {/*            Guntakal Division - Construction of Road Over Bridge as*/}
                                        {/*            1x28.284m(Sk)/*/}
                                        {/*            20.0m(Sq) PSC Girder + 1x42.426m(Sk)/30.0m(Sq) Composite Girder*/}
                                        {/*            (steel*/}
                                        {/*            'I' girder with RCC desk slab) + 1x28.284m(Sk)/ 20.0m(Sq) PSC Girder*/}
                                        {/*            on*/}
                                        {/*            pile foundation to cater 2 lane ROB at Km:347,33-35 between*/}
                                        {/*            Regadapalli*/}
                                        {/*            and Vanganur stations (Deposit work)*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</VerticalTimelineElement>*/}
                                    </VerticalTimeline>
                                </div>
                            </div>
                        </div>
                    </div>

                {/*  footer  */}

                    <FooterPage />
                </div>
            </div>
        </div>
    )
}

export default Experience;
