import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import {useEffect} from "react";

const Achievements = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={'container-fluid'}>
            <div className={"row bg-white"}>
                <div className={"col-12 other-page-nav-color"}>

                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  achievements  */}

                    <div className={"row justify-content-center"}>
                        <div className={"col-12 my-md-5 py-3 my-3"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label
                                    className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Achievements</label>
                            </div>

                            <div className={"row justify-content-center mt-md-5 bg-achievements-container"}>
                                <div className={"col-md-10 col-12 mt-4 pt-2"}>
                                    <div
                                        className={"row justify-content-center justify-content-md-between align-items-center"}>
                                        <div className={"col-10 col-md-6 pe-md-5"}>
                                            <img className={"img-fluid h-100 w-100"}
                                                 src={'/images/achievements/nhidcl-document.png'}
                                                 alt={'achievements-image'}/>
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <div className={"w-100 mt-4 mt-md-0 px-1 px-md-0"}>
                                                <label
                                                    className={"font-size-32 font-zaf-renaissance font-weight-400 font-color-blue"}>
                                                    National Highways & Infrastructure Development Corporation LTD
                                                </label>
                                                <div className={"my-md-4 my-2"}>
                                                    <label
                                                        className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                        style={{textAlign: 'justify'}}>
                                                        MVVS proudly completed the <span
                                                        className={"font-color-orange"}>
                                                             "Construction of balance work of 2-laning of the Akajan-Likabali-Bame Road" under the National Highways & Infrastructure Development Corporation LTD (NHIDCL).
                                                        </span> This milestone project, part of the SARDP-NE initiative,
                                                        covered
                                                        the stretch from km 33.000 to 65.810 (existing km 33.000 to
                                                        71.000)
                                                        in Arunachal Pradesh. By utilizing the latest in road
                                                        construction technology and following the EPC (Engineering,
                                                        Procurement, and Construction) model, our team ensured
                                                        high-quality, durable infrastructure, facilitating smoother
                                                        connectivity and boosting regional development.
                                                    </label>
                                                </div>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                    style={{textAlign: 'justify'}}>
                                                    This project reaffirms our commitment to delivering top-notch civil
                                                    engineering solutions, positioning MVVS as a trusted name in the
                                                    construction industry.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row justify-content-center mt-md-5 bg-achievements-container-2"}>
                                <div className={"col-md-10 col-12 mt-4 pt-4"}>
                                    <div className={"row justify-content-center align-items-center"}>
                                        <div className={"col-12 col-md-6  order-1 order-md-0"}>
                                            <div className={"w-100 mt-4 mt-md-0 px-1 px-md-0"}>
                                                <label
                                                    className={"font-size-32 font-zaf-renaissance font-weight-400 font-color-blue"}>
                                                    South Western Railway
                                                </label>
                                                <div className={"my-md-4 my-2"}>
                                                    <label
                                                        className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                        style={{textAlign: 'justify'}}>
                                                        We are proud to announce the successful completion of a key
                                                        infrastructure project: the Yelahanka-Penukonda doubling. This
                                                        milestone involved the construction of <span
                                                        className={"font-color-orange"}>major bridges between Devarapalli (km 87/350) and Penukonda
                                                        (km 136/350) stations, alongside a state-of-the-art
                                                        Road Over Bridge (ROB) replacing LC 81 at Penukonda Yard </span>
                                                        to accommodate the new doubling line. Our expert team delivered
                                                        this critical project with precision, enhancing connectivity and
                                                        rail efficiency in the region.
                                                    </label>
                                                </div>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                    style={{textAlign: 'justify'}}>
                                                    As a leading construction company, we specialize in large-scale
                                                    infrastructure projects, providing cutting-edge construction
                                                    services that shape the future of transportation and development.
                                                </label>
                                            </div>
                                        </div>
                                        <div className={"col-10 col-md-6 ps-md-5"}>
                                            <img className={"img-fluid h-100 w-100"}
                                                 src={'/images/achievements/swr-document.png'}
                                                 alt={'achievements-image'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row justify-content-center mt-md-5 bg-achievements-container"}>
                                <div className={"col-md-10 col-12 mt-4 pt-4"}>
                                    <div
                                        className={"row justify-content-center justify-content-md-between align-items-center"}>
                                        <div className={"col-10 col-md-6 pe-md-5"}>
                                            <img className={"img-fluid h-100 w-100"}
                                                 src={'/images/achievements/government-of-assam-document.png'}
                                                 alt={'achievements-image'}/>
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <div className={"w-100 mt-4 mt-md-0 px-1 px-md-0"}>
                                                <label
                                                    className={"font-size-32 font-zaf-renaissance font-weight-400 font-color-blue"}>
                                                    Government of Assam
                                                </label>
                                                <div className={"my-md-4 my-2"}>
                                                    <label
                                                        className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                        style={{textAlign: 'justify'}}>
                                                        Our company proudly completed the prestigious <span
                                                        className={"font-color-orange"}>Assam Bhavan Complex,
                                                        a multi-storied construction project in Bangalore, Karnataka,
                                                        including a state-of-the-art convention center.</span> This
                                                        achievement showcases our expertise in executing large-scale
                                                        infrastructure projects with precision and dedication. The
                                                        completion of this complex, after taking on the balance work,
                                                        highlights our commitment to delivering high-quality
                                                        construction services on time.
                                                    </label>
                                                </div>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                    style={{textAlign: 'justify'}}>
                                                    As a leading construction company, we specialize in multi-story
                                                    buildings, commercial complexes, and infrastructure projects,
                                                    ensuring superior craftsmanship and customer satisfaction. Trust us
                                                    for all your construction needs—where quality meets excellence.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row justify-content-center mt-md-5 bg-achievements-container-2"}>
                                <div className={"col-md-10 col-12 mt-4 pt-4"}>
                                    <div
                                        className={"row justify-content-center justify-content-md-between align-items-center"}>
                                        <div className={"col-12 col-md-6 order-1 order-md-0"}>
                                            <div className={"w-100 mt-4 mt-md-0 px-1 px-md-0"}>
                                                <label
                                                    className={"font-size-32 font-zaf-renaissance  font-weight-400 font-color-blue"}>
                                                    South Western Railway
                                                </label>
                                                <div className={"my-md-4 my-2"}>
                                                    <label
                                                        className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                        style={{textAlign: 'justify'}}>
                                                        Our construction company successfully completed another
                                                        prestigious project for Southern Railway, construction of <span
                                                        className={"font-color-orange"}>Third Coaching Terminal : Cover over for
                                                        parking area and structural steel roofing of concourse/canopy
                                                        over station building upto Platform No.2 and 3, water
                                                        supply management system with SCADA and other miscellaneous
                                                        works at Baiyyappanahalli station. (TWO PACKET SYSTEM).</span> With
                                                        a skilled team and expertise in structural solutions, we
                                                        executed this complex project with unmatched quality, delivering
                                                        lasting results.
                                                    </label>
                                                </div>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-color-neutral-grey-1"}
                                                    style={{textAlign: 'justify'}}>
                                                    From unique structures to scalable designs, our dedication to
                                                    excellence ensures client satisfaction every time. We specialise in
                                                    providing comprehensive construction services for projects of any
                                                    size, shape, or complexity. Your vision, our precision—always built
                                                    to last.
                                                </label>
                                            </div>
                                        </div>
                                        <div className={"col-10 col-md-6 ps-md-5"}>
                                            <img className={"img-fluid h-100 w-100"}
                                                 src={'/images/achievements/swr-2-document.png'}
                                                 alt={'achievements-image'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>

                </div>
            </div>
        </div>
    )
}

export default Achievements;
