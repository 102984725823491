import {Link} from "react-router-dom";
import * as Icon from 'react-feather';

const FooterPage = () => {
    return (
        <div className={"row bg-blue justify-content-center"}>
            <div className={"col-md-11 col-12 py-md-5 py-2 my-md-3 my-4"}>
                <div className={"row justify-content-md-between justify-content-center"}>
                    <div className={"col-11 col-md-3 br-12 bg-orange d-flex align-items-stretch"}>
                        <div className={"w-100 d-flex flex-column justify-content-center align-items-center p-4 p-md-3 "}>
                            <Link to={"/"}>
                                <img src={"/images/home/mvvs-logo.png"} alt={"logo"} className={"img-fluid"}/>
                            </Link>
                        </div>
                    </div>
                    <div className={"col-11 col-md-3 d-flex align-items-stretch b-1-light-color br-12 mt-3 mt-md-0"}>
                        <div className={"d-flex d-lg-none flex-wrap p-4 column-gap-md-3 column-gap-4 row-gap-4"}>
                            <div className={"d-flex p-0 justify-content-start"}>
                                <Link to={"/about-us"}>
                                    <label
                                        className={"font-size-18 font-weight-315 font-sequel-sans-medium nav-bar-item services-button text-start font-color-brand-light"}>About
                                        Us</label>
                                </Link>
                            </div>
                            <div className={" d-flex p-0 justify-content-start"}>
                                <Link to={"/services"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Services</label>
                                </Link>
                            </div>
                            <div className={" d-flex p-0 justify-content-start"}>
                                <Link to={"/projects"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item font-sequel-sans-medium text-start services-button font-color-brand-light"}>Projects</label>
                                </Link>
                            </div>
                            <div className={"d-flex p-0 justify-content-start"}>
                                <Link to={"/achievements"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Achievements</label>
                                </Link>
                            </div>
                            <div className={" d-flex p-0 justify-content-start"}>
                                <Link to={"/financial"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start font-sequel-sans-medium services-button font-color-brand-light"}>Financials</label>
                                </Link>
                            </div>
                            <div className={" d-flex p-0 justify-content-start"}>
                                <Link to={"/careers"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Career</label>
                                </Link>
                            </div>
                            <div className={"col-12 p-0"}>
                                {/*<div>*/}
                                {/*    <Link to={"/about-us"}>*/}
                                {/*        <label*/}
                                {/*            className={"font-size-18 font-weight-315 font-sequel-sans-medium nav-bar-item services-button text-start font-color-brand-light"}>About*/}
                                {/*            Us</label>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                                {/*<div className={""}>*/}
                                {/*    <Link to={"/services"}>*/}
                                {/*        <label*/}
                                {/*            className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Services</label>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                                {/*<div className={""}>*/}
                                {/*    <Link to={"/projects"}>*/}
                                {/*        <label*/}
                                {/*            className={"font-size-18 font-weight-315 nav-bar-item font-sequel-sans-medium text-start services-button font-color-brand-light"}>Projects</label>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                                {/*<div className={""}>*/}
                                {/*    <Link to={"/achievements"}>*/}
                                {/*        <label*/}
                                {/*            className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Achievements</label>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                                {/*<div className={""}>*/}
                                {/*    <Link to={"/financial"}>*/}
                                {/*        <label*/}
                                {/*            className={"font-size-18 font-weight-315 nav-bar-item text-start font-sequel-sans-medium services-button font-color-brand-light"}>Financials</label>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                                {/*<div className={""}>*/}
                                {/*    <Link to={"/careers"}>*/}
                                {/*        <label*/}
                                {/*            className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Career</label>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}

                                <div className={"w-100"}>
                                    <label
                                        className={"font-size-14 font-weight-305 font-color-neutral-white font-opacity-60"}>©
                                        2024. All
                                        rights reserved.</label>
                                </div>
                            </div>
                            {/*<div className={"col-6 p-0"}>*/}
                            {/*    <div>*/}
                            {/*        <Link to={"/about-us"}>*/}
                            {/*            <label*/}
                            {/*                className={"font-size-18 font-weight-315 font-sequel-sans-medium nav-bar-item services-button text-start font-color-brand-light"}>About*/}
                            {/*                Us</label>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*    <div className={"mt-4"}>*/}
                            {/*        <Link to={"/projects"}>*/}
                            {/*            <label*/}
                            {/*                className={"font-size-18 font-weight-315 nav-bar-item font-sequel-sans-medium text-start services-button font-color-brand-light"}>Projects</label>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*    <div className={"mt-4"}>*/}
                            {/*        <Link to={"/financial"}>*/}
                            {/*            <label*/}
                            {/*                className={"font-size-18 font-weight-315 nav-bar-item text-start font-sequel-sans-medium services-button font-color-brand-light"}>Financials</label>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className={"col-6 p-0"}>*/}
                            {/*    <div>*/}
                            {/*        <Link to={"/services"}>*/}
                            {/*            <label*/}
                            {/*                className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Services</label>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*    <div className={"mt-4"}>*/}
                            {/*        <Link to={"/achievements"}>*/}
                            {/*            <label*/}
                            {/*                className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Achievements</label>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*    <div className={"mt-4"}>*/}
                            {/*        <Link to={"/careers"}>*/}
                            {/*            <label*/}
                            {/*                className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Career</label>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className={"d-none row d-lg-flex flex-wrap p-4"}>
                            <div className={"col-md-6 col-4 d-flex p-0 justify-content-start"}>
                                <Link to={"/about-us"}>
                                    <label
                                        className={"font-size-18 font-weight-315 font-sequel-sans-medium nav-bar-item services-button text-start font-color-brand-light"}>About
                                        Us</label>
                                </Link>
                            </div>
                            <div className={"col-md-6 col-4 d-flex p-0 justify-content-start"}>
                                <Link to={"/services"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Services</label>
                                </Link>
                            </div>
                            <div className={"col-md-6 col-4 d-flex p-0 justify-content-start"}>
                                <Link to={"/projects"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item font-sequel-sans-medium text-start services-button font-color-brand-light"}>Projects</label>
                                </Link>
                            </div>
                            <div className={"col-md-6 col-4 d-flex p-0 justify-content-start"}>
                                <Link to={"/achievements"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Achievements</label>
                                </Link>
                            </div>
                            <div className={"col-md-6 col-4 d-flex p-0 justify-content-start"}>
                                <Link to={"/financial"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start font-sequel-sans-medium services-button font-color-brand-light"}>Financials</label>
                                </Link>
                            </div>
                            <div className={"col-md-6 col-4 d-flex p-0 justify-content-start"}>
                                <Link to={"/careers"}>
                                    <label
                                        className={"font-size-18 font-weight-315 nav-bar-item text-start services-button font-sequel-sans-medium font-color-brand-light"}>Career</label>
                                </Link>
                            </div>
                            <div className={"col-12 p-0"}>
                                <div className={"w-100"}>
                                    <label
                                        className={"font-size-14 font-weight-305 font-color-neutral-white font-opacity-60"}>©
                                        2024. All
                                        rights reserved.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={"col-11 col-md-4 mt-3  p-4  b-1-light-color br-12 mt-md-0 d-flex align-items-stretch"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label
                                    className={"font-size-14 font-weight-305 font-color-neutral-white font-sequel-sans-medium font-opacity-60"}>Address:</label>
                                <div>
                                    <label className={"font-color-neutral-white font-size-16 font-weight-305"}>H.NO.6-1-136/3,
                                        Meher Ratna Complex, Street No.10, Balram Colony, Walker
                                        Town, Padmarao Nagar, Securnderbad - 500 020
                                    </label>
                                </div>
                            </div>
                            <div className={"col-12 mt-md-5 mt-4"}>
                                <label
                                    className={"font-size-14 font-weight-305 font-color-neutral-white font-sequel-sans-medium font-opacity-60"}>Contact:</label>
                                <div>
                                    <a href={"mailto:admin@mvvs.info"}>
                                        <label
                                            className={"font-color-neutral-white services-button text-wrap font-size-16 font-weight-305"}>admin@mvvs.info</label>
                                    </a>
                                </div>
                                {/*<a href={"mailto:mvvs.blr@gmail.com"}>*/}
                                {/*    <label*/}
                                {/*        className={"font-color-neutral-white services-button font-size-16 font-weight-305"}>mvvs.blr@gmail.com</label>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                    <div className={"col-11 col-md-1 mt-3 mt-md-0 d-md-flex align-items-md-stretch"}>
                        <div className={"row b-1-light-color align-items-md-center p-4 br-12"}>
                            <div
                                className={"col-12 w-100 d-flex flex-md-column gap-md-3 gap-5 justify-content-between p-0"}>
                                <div className={"bg-orange p-2 br-3"}>
                                    <Icon.Instagram color={"#081250"} strokeWidth={2}/>
                                </div>
                                <div className={"bg-orange p-2 br-3 "}>
                                    <Icon.Facebook color={"#081250"} strokeWidth={2}/>
                                </div>
                                <div className={"bg-orange p-2 br-3 "}>
                                    <Icon.Linkedin color={"#081250"} strokeWidth={2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-12 col-11 d-flex justify-content-md-end mt-4 pt-md-3"}>
                        <div>
                            <label className={"font-size-16 font-weight-305 font-color-neutral-white"}>Designed &
                                Developed by <a href={"https://7dotss.com"} target={"_blank"} rel="noreferrer"
                                                className={"text-decoration-none font-color-orange"}>7 Dots</a></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterPage;
