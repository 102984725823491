import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import React, {useEffect, useState} from "react";
import servicesData from '../Models/services.json';
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Autoplay, Pagination} from "swiper/modules";
import * as Icon from 'react-feather';
// import {InView} from "react-intersection-observer";

const Services = () => {

    const [services, setServices] = useState([]);

    const goToSlideNext = (swiper) => {
        const swiperRef = document.getElementById(swiper);
        console.log(swiper)
        if (swiperRef) {
            swiperRef.swiper.slideNext();
        }
    };

    const goToSlidePrev = (swiper) => {
        const swiperRef = document.getElementById(swiper);
        if (swiperRef) {
            swiperRef.swiper.slidePrev();
        }
    };

    // const startCurrentSwiperAutoPlay = (currentSwiper) => {
    //     const inViewSwiper = document.getElementById(currentSwiper);
    //     if(inViewSwiper) {
    //         inViewSwiper.swiper.params.autoplay.delay = 1;
    //         inViewSwiper.swiper.autoplay.start();
    //     }
    // }
    //
    // const stopPrevSwiperAutoPlay = (currentSwiper) => {
    //     const inViewSwiper = document.getElementById(currentSwiper);
    //     if(inViewSwiper) {
    //         inViewSwiper.swiper.autoplay.stop();
    //     }
    // }

    useEffect(() => {
        setServices(servicesData);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12 other-page-nav-color"}>

                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  hero section  */}
                    <div className={"row justify-content-center py-md-5 py-4"}>
                        <div className={"col-md-10 col-11 my-md-3 my-2 bg-blue br-32"}>
                            <div className={"row justify-content-between align-items-center p-md-4 p-3"}>
                                <div className={"col-md-6 col-12 p-0"}>
                                    <div className={"w-100"}>
                                        <div className={"d-flex justify-content-center justify-content-md-start"}>
                                            <label
                                                className={"font-size-56 font-weight-400 font-color-neutral-white font-zaf-renaissance"}>Services</label>
                                        </div>
                                        <div className={"mt-md-3 mt-2"}>
                                            <label className={"font-size-16 font-weight-305 font-color-neutral-white"} style={{textAlign: 'justify'}}>
                                                MVVS ensures expert planning and design of structures, considering space
                                                ambience, functionality, and all technical, social, environmental, and
                                                aesthetic factors. Our construction services guarantee seamless
                                                collaboration between engineers, architects, clients, and users,
                                                delivering structures that are both functional and visually appealing,
                                                while ensuring long-term ease of maintenance.
                                            </label>
                                        </div>
                                        <div className={"mt-4 pt-md-3"}>
                                            <Link to={"/contact-us"}>
                                                <button type={'button'}
                                                        className={"bg-contact-us-btn border-0 font-sequel-sans-medium bg-orange font-size-16 font-weight-315 font-color-blue"}>Contact
                                                    us
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-5 mt-4 pt-2 mt-md-0 pt-md-0 col-12 px-0"}>
                                    <img className={"img-fluid w-100"} src={"/images/services/services-hero-image.png"}
                                         alt={"services-hero-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  services details  */}
                    <div className={"row justify-content-center"}>
                        <div className={"col-11 col-md-10 py-md-5 py-4 my-md-3 my-2"}>

                            {services.map((eachService, index) => (
                                <div key={index}>
                                    <div className={"row justify-content-between"}>
                                        <div
                                            className={`col-12 col-md-5 ${index % 2 === 0 ? 'order-md-0 order-1' : 'order-1'}  mt-4 pt-2 mt-md-0 pt-md-0 p-0`}>
                                            {/*<InView rootMargin={"-50% 0% -50% 0%"} onChange={(inView, entry) => {*/}
                                            {/*    if(inView) {*/}
                                            {/*        startCurrentSwiperAutoPlay(`services-swiper-${index}`)*/}
                                            {/*    }*/}
                                            {/*    else {*/}
                                            {/*        stopPrevSwiperAutoPlay(`services-swiper-${index}`)*/}
                                            {/*    }*/}
                                            {/*}}>*/}
                                            {/*    */}
                                            {/*</InView>*/}
                                            <Swiper
                                                className={`w-100`}
                                                id={`services-swiper-${index}`}
                                                speed={(index % 2 === 0) ? 300 : 500}
                                                spaceBetween={10}
                                                slidesPerView={1}
                                                autoplay={{delay: 1000 ,waitForTransition: true, pauseOnMouseEnter: true}}
                                                pagination={{el: `.custom-pagination-${index}`, clickable: true}}
                                                modules={[Autoplay, Pagination]}
                                                grabCursor={true}
                                                loop={true}
                                            >
                                                {eachService.services_images.length > eachService.key_projects_completed.length ? (
                                                    eachService.services_images.slice(0, eachService.key_projects_completed.length).map((eachImage, index) => (
                                                        <SwiperSlide key={index}>
                                                            <img className={"img-fluid w-100 br-32 b-2-blue"}
                                                                 src={eachImage.image_url}
                                                                 alt={eachImage.alt}/>
                                                        </SwiperSlide>
                                                    ))
                                                ) : (eachService.services_images.map((eachImage, index) => (
                                                    <SwiperSlide key={index}>
                                                        <img className={"img-fluid w-100 br-32 b-2-blue"}
                                                             src={eachImage.image_url}
                                                             alt={eachImage.alt}/>
                                                    </SwiperSlide>)
                                                ))}
                                            </Swiper>

                                            <div className={"d-flex  mt-3  justify-content-between align-items-center"}>
                                                <div
                                                    className={`custom-pagination-${index} services-swiper-container`}></div>
                                                <div className={"w-100 d-flex justify-content-end gap-3"}>
                                                    <button type={"button"}
                                                            className={"p-2 bg-transparent b-1-blue rounded-circle"}
                                                            onClick={() => goToSlidePrev(`services-swiper-${index}`)}
                                                    >
                                                        <Icon.ArrowLeft color={"#081250"} size={24}/>
                                                    </button>
                                                    <div>
                                                        <button type={"button"}
                                                                className={"p-2 bg-transparent b-1-blue rounded-circle"}
                                                                onClick={() => goToSlideNext(`services-swiper-${index}`)}
                                                        >
                                                            <Icon.ArrowRight color={"#081250"} size={24}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={"col-12 col-md-6 p-md-0"}>
                                            <div className={"row p-0"}>
                                                <div className={"col-12 p-0"}>
                                                    <label
                                                        className={"font-color-orange font-size-18 font-sequel-sans-medium font-weight-315"}>{eachService.id}</label>
                                                    <div className={"service-content mt-md-3 mt-2 w-100"}>
                                                        <label
                                                            className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>{eachService.service_name}</label>
                                                        <div className={"mt-2"}>
                                                            <label
                                                                className={"font-size-18 font-weight-305 font-color-blue"} style={{textAlign: 'justify'}}>
                                                                {eachService.service_description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*  Key Projects Completed  */}
                                                <div className={"col-12 mt-4 pt-md-3 px-0"}>
                                                    <label
                                                        className={"font-size-32 font-weight-400 font-zaf-renaissance font-color-blue"}>Key
                                                        Projects
                                                        Completed</label>
                                                    {eachService.key_projects_completed.map((eachKey, index) => (
                                                            <div className={"d-flex justify-content-start mt-md-4 mt-3"}
                                                                 key={index}>
                                                                <div className={"project-item"}></div>
                                                                <label
                                                                    className={"font-size-18 ms-4 font-weight-305 font-color-blue"} style={{textAlign: 'justify'}}>
                                                                    {eachKey}
                                                                </label>
                                                            </div>
                                                        )
                                                    )}


                                                    {/*<div className={"project-item mt-md-4 mt-3"}>*/}
                                                    {/*    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                                                    {/*        Construction of General Hospital at Govindrajnagar, Bangalore-20*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                    {/*<div className={"project-item mt-md-4 mt-3"}>*/}
                                                    {/*    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                                                    {/*        SMBVT first smart Railway Station of South India.*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                    {/*<div className={"project-item mt-md-4 mt-3"}>*/}
                                                    {/*    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                                                    {/*        Construction of Multi-specialty Hospital at Pantharapalya,*/}
                                                    {/*        Bangalore-26*/}
                                                    {/*    </label>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {index < (services.length - 1) && (
                                        <div className={"row mt-md-5 mt-4 pt-3 pt-md-5 "}>
                                            <div className={"col-12 mt-4 pt-2 mt-md-0 pt-md-0"}></div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            {/* construction */}

                            {/* bridges */}
                            {/*<div className={"row justify-content-between mt-md-5 mt-4 pt-3 pt-md-5 "}>*/}
                            {/*    <div className={"col-12 col-md-5 p-0 mt-4 pt-2 mt-md-0 pt-md-0 p-0 order-1"}>*/}
                            {/*        <div className={"image-container w-100"}>*/}
                            {/*            <img className={"img-fluid w-100"}*/}
                            {/*                 src={"/images/services/services-bridges-image.png"}*/}
                            {/*                 alt={"services-bridges-image"}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={"col-12 col-md-6 p-md-0"}>*/}
                            {/*        <div className={"row p-0"}>*/}
                            {/*            <div className={"col-12 p-0"}>*/}
                            {/*                <label*/}
                            {/*                    className={"font-color-orange font-size-18 font-weight-315"}>02</label>*/}
                            {/*                <div className={"service-content mt-md-3 mt-2 w-100"}>*/}
                            {/*                    <label*/}
                            {/*                        className={"font-size-56 font-weight-400 font-color-blue"}>Bridges</label>*/}
                            {/*                    <div className={"mt-2"}>*/}
                            {/*                        <label className={"font-size-18 font-weight-305 font-color-blue"}>*/}
                            {/*                            Our expertise in bridge engineering is well-recognized, with a*/}
                            {/*                            portfolio of completed projects that includes various types of*/}
                            {/*                            bridges. MVVS has executed the maximum span of a Road Over*/}
                            {/*                            Bridge of 1 x 72.00 m Bow String Girder, RCC Road Under Bridge*/}
                            {/*                            of span of 2 X 11.40 m with Air Pushing Method, Road Over Bridge*/}
                            {/*                            of span of 1 x 48.00 m OPEN WEB GIRDER for railway traffic, Foot*/}
                            {/*                            Over Bridge of 40.00 m x 12.00 m width over electrified railway*/}
                            {/*                            traffic. MVVS is expertise in bridges with various types of*/}
                            {/*                            foundations like Pile foundations, Well foundations, Open*/}
                            {/*                            foundations.*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            /!*  Key Projects Completed  *!/*/}
                            {/*            <div className={"col-12 mt-4 pt-md-3 px-0"}>*/}
                            {/*                <label className={"font-size-32 font-weight-400 font-color-blue"}>Key*/}
                            {/*                    Projects*/}
                            {/*                    Completed</label>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Construction of Major bridges in Yelahanka- Penukonda Doubling*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Construction of Road over Bridge at Devanagonthi Railway station*/}
                            {/*                        yard.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Construction of Foot over Bridge at Bangalore city Railway station.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*/!* road works *!/*/}
                            {/*<div className={"row justify-content-between mt-md-5 mt-4 pt-3 pt-md-5 "}>*/}
                            {/*    <div className={"col-12 col-md-5 order-md-0 order-1 mt-4 pt-2 mt-md-0 pt-md-0 p-0"}>*/}
                            {/*        <div className={"image-container w-100"}>*/}
                            {/*            <img className={"img-fluid w-100"}*/}
                            {/*                 src={"/images/services/services-road-works-image.png"}*/}
                            {/*                 alt={"services-road-works-image"}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={"col-12 col-md-6 p-md-0"}>*/}
                            {/*        <div className={"row p-0"}>*/}
                            {/*            <div className={"col-12 p-0"}>*/}
                            {/*                <label*/}
                            {/*                    className={"font-color-orange font-size-18 font-weight-315"}>03</label>*/}
                            {/*                <div className={"service-content mt-md-3 mt-2 w-100"}>*/}
                            {/*                    <label className={"font-size-56 font-weight-400 font-color-blue"}>*/}
                            {/*                        Roads Works*/}
                            {/*                    </label>*/}
                            {/*                    <div className={"mt-2"}>*/}
                            {/*                        <label className={"font-size-18 font-weight-305 font-color-blue"}>*/}
                            {/*                            Our expertise in road ways is well-recognized, with a portfolio*/}
                            {/*                            of completed projects that includes various types of road works.*/}
                            {/*                            Our team is committed to execute the formations, minor bridges,*/}
                            {/*                            which are included to complete the road works. MVVS has executed*/}
                            {/*                            the Construction of Two laning of the existing*/}
                            {/*                            Akajan-Likabali-Bame road on EPC basis for 32.00 km in the state*/}
                            {/*                            of Arunachal Pradesh.*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            /!*  Key Projects Completed  *!/*/}
                            {/*            <div className={"col-12 mt-4 pt-md-3 px-0"}>*/}
                            {/*                <label className={"font-size-32 font-weight-400 font-color-blue"}>Key*/}
                            {/*                    Projects*/}
                            {/*                    Completed</label>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Construction of ladies’ hostel building for Bangalore University*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Construction of General Hospital at Govindrajnagar, Bangalore-20*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        SMBVT first smart Railway Station of South India.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Construction of Multi-specialty Hospital at Pantharapalya,*/}
                            {/*                        Bangalore-26*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*/!* track linking *!/*/}
                            {/*<div className={"row justify-content-between mt-md-5 mt-4 pt-3 pt-md-5 "}>*/}
                            {/*    <div className={"col-12 col-md-5 mt-4 pt-2 mt-md-0 pt-md-0 p-0 order-1"}>*/}
                            {/*        <div className={"image-container w-100"}>*/}
                            {/*            <img className={"img-fluid w-100"}*/}
                            {/*                 src={"/images/services/services-track-linking-image.png"}*/}
                            {/*                 alt={"services-track-linking-image"}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={"col-12 col-md-6 p-md-0"}>*/}
                            {/*        <div className={"row p-0"}>*/}
                            {/*            <div className={"col-12 p-0"}>*/}
                            {/*                <label*/}
                            {/*                    className={"font-color-orange font-size-18 font-weight-315"}>04</label>*/}
                            {/*                <div className={"service-content mt-md-3 mt-2 w-100"}>*/}
                            {/*                    <label className={"font-size-56 font-weight-400 font-color-blue"}>*/}
                            {/*                        Track Linking*/}
                            {/*                    </label>*/}
                            {/*                    <div className={"mt-2"}>*/}
                            {/*                        <label className={"font-size-18 font-weight-305 font-color-blue"}>*/}
                            {/*                            Track work is another area where MVVS excels. We have a skilled*/}
                            {/*                            labour force that specialises in track linking work, executing*/}
                            {/*                            projects with a level of precision and quality that is*/}
                            {/*                            unmatched. We have successfully completed major track linking*/}
                            {/*                            projects, including the sections between Yelahanka station -*/}
                            {/*                            Penukonda station , Tumkur station - Arsikere station, Mysore*/}
                            {/*                            station - Mandya station doubling of tracks, and the Hassan-*/}
                            {/*                            Bangalore new line, demonstrating our capability to handle*/}
                            {/*                            large-scale railway infrastructure projects.*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            /!*  Key Projects Completed  *!/*/}
                            {/*            <div className={"col-12 mt-4 pt-md-3 px-0"}>*/}
                            {/*                <label className={"font-size-32 font-weight-400 font-color-blue"}>*/}
                            {/*                    Key Projects Completed*/}
                            {/*                </label>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Yelahanka- Penukonda project, Doubling of track 50 Km Length.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Tumkur- Arsikere project, Doubling of track 43 Km Length.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Hassan- Bangalore project, new line 167 Km Length.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*                <div className={"project-item mt-md-4 mt-3"}>*/}
                            {/*                    <label className={"font-size-18 ms-4 font-weight-305 font-color-blue"}>*/}
                            {/*                        Mysore-Mandya project, Doubling of track 45 Km Length.*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*  Have a project in mind?  */}
                    <div className={"row bg-promotion-container"}>
                        <div className={"col-12"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-8 col-12 py-md-5 py-4 my-md-5  "}>
                                    <div className={"row justify-content-center"}>
                                        <div className={"col-md-10 col-11"}>
                                            <label
                                                className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-orange"}>
                                                Have a project in mind?
                                            </label>
                                            <div className={"mt-md-4 mt-2"}>
                                                <h3 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                                    We'll work closely with you to understand your needs and create a
                                                    custom
                                                    design that reflects your style and values.
                                                </h3>
                                            </div>
                                            <div className={"mt-4 mt-md-3"}>
                                                <Link to={"/contact-us"}>
                                                    <button type={"button"}
                                                            className={"bg-contact-us-btn border-0 font-sequel-sans-medium bg-orange font-color-blue font-size-16 font-weight-315"}>
                                                        Contact Us
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-4 d-md-block d-none p-0"}>
                                    <img className={"img-fluid w-100 h-100"}
                                         src={"/images/home/night-view-bridge-image.png"}
                                         alt={"night-view-bridge-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default Services;
