import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import {useEffect, useState} from "react";
import projectsData from '../Models/projects.json';
import {Link} from "react-router-dom";
import * as Icon from 'react-feather';
import {Accordion} from "react-bootstrap";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [projectStatus, setProjectStatus] = useState("all projects");
    const [services, setServices] = useState("all services");
    const [filterData, setFilterData] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const toggleFilter = () => {
        setIsFilterOpen(prevState => !prevState);
    };

    const handleOnChangeProjectStatus = (status) => {
        setProjectStatus(status);
    }

    const handleOnChangeServices = (service) => {
        setServices(service);
    }

    const filterProjects = () => {
        let filtered = projects;

        if (projectStatus !== 'all projects' && services !== 'all services') {
            filtered = filtered.filter((project) => ((project.project_tag.toLowerCase() === services.toLowerCase()) && (project.project_status === projectStatus.toLowerCase())))
        } else if (projectStatus !== 'all projects') {
            filtered = filtered.filter((project) => (project.project_status === projectStatus.toLowerCase()))
        } else if (services !== 'all services') {
            filtered = filtered.filter((projectTag) => projectTag.project_tag.toLowerCase() === services.toLowerCase());
        }
        setFilterData(filtered);
    };

    useEffect(() => {
        filterProjects();
    }, [services, projectStatus,])


    useEffect(() => {
        setProjects(projectsData);
        window.scrollTo(0, 0)
    }, []);


    return (
        <div className={"container-fluid"}>
            <div className={'row'}>
                <div className={"col-12 other-page-nav-color"}>
                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  projects section  */}
                    <div className={"row justify-content-center"}>
                        <div className={"col-md-10 col-12 py-md-5 py-3 my-3"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label
                                    className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Projects</label>
                            </div>

                            {/*  filters  */}
                            <div className={"row d-none d-md-flex justify-content-start mt-4 pt-md-3"}>
                                <div className={"col-md-3 col-6"}>
                                    <select className={"form-select font-size-14 font-color-neutral-grey-2 py-3"}
                                            value={projectStatus}
                                            onChange={(event) => handleOnChangeProjectStatus(event.target.value)}>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'all projects'}>All Projects
                                        </option>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'completed'}>Completed
                                        </option>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'ongoing'}>Ongoing
                                        </option>
                                    </select>
                                </div>
                                <div className={"col-md-3 col-6"}>
                                    <select className={"form-select font-color-neutral-grey-2 py-3"} value={services}
                                            onChange={(event) => handleOnChangeServices(event.target.value)}>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'all services'}>All Services
                                        </option>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'building construction'}>Building Construction
                                        </option>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={"bridge"}>Bridges
                                        </option>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'track linking'}>Track Linking
                                        </option>
                                        <option className={"font-size-14 font-weight-305 font-color-blue"}
                                                value={'roads'}>Roads
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div className={"row d-flex d-md-none"}>
                                <div className={"col-12 d-flex justify-content-end"}>
                                    <button type={"button"} onClick={toggleFilter}
                                            className={"bg-transparent b-1-grey-3 br-8 font-size-14 font-color-blue font-weight-305 p-3"}>
                                        Filter <Icon.Filter className={"ms-1"} color={"#081250"} size={18}/>
                                    </button>

                                    <div className={`row filter-container w-100 m-0 z-1 ${isFilterOpen ? 'open' : ''}`}>
                                        <div className={"col-12"}>
                                            <div className={"w-100 d-flex justify-content-between align-items-center"}>
                                                <label
                                                    className={"font-size-24 font-weight-400 font-zaf-renaissance font-color-blue"}>Filter</label>
                                                <div>
                                                    <Icon.X color={"#081250"} onClick={toggleFilter}/>
                                                </div>
                                            </div>
                                            <div className={"w-100"}>
                                                <Accordion defaultActiveKey="0" flush>
                                                    <Accordion.Item eventKey="0" className={"bb-1-light-brown mt-3"}>
                                                        <Accordion.Header
                                                            className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Progress</Accordion.Header>
                                                        <Accordion.Body className={"p-0"}>
                                                            <div className={"row"}>
                                                                <div className={"col-12"}>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>All
                                                                            Projects
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setProjectStatus(e.target.value)}
                                                                                   value={'all projects'}
                                                                                   name={"progress"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'all-projects'}*/}
                                                                        {/*       value={'all projects'} name={"progress"}*/}
                                                                        {/*       />*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"all-projects"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>All*/}
                                                                        {/*        Projects</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            Completed
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setProjectStatus(e.target.value)}
                                                                                   value={'completed'}
                                                                                   name={"progress"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'completed'}*/}
                                                                        {/*       value={'completed'} name={"progress"}*/}
                                                                        {/*       onChange={(e) => setProjectStatus(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"completed"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>Completed</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            Ongoing
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setProjectStatus(e.target.value)}
                                                                                   value={'ongoing'}
                                                                                   name={"progress"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'on-going'}*/}
                                                                        {/*       value={'ongoing'} name={"progress"}*/}
                                                                        {/*       onChange={(e) => setProjectStatus(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"on-going"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>Ongoing</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1" className={"mt-3"}>
                                                        <Accordion.Header
                                                            className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Services</Accordion.Header>
                                                        <Accordion.Body className={"p-0"}>
                                                            <div className={"row"}>
                                                                <div className={"col-12"}>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            All Services
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setServices(e.target.value)}
                                                                                   value={'all services'}
                                                                                   name={"services"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'all-services'}*/}
                                                                        {/*       value={'all services'} name={"services"}*/}
                                                                        {/*       onChange={(e) => setServices(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"all-services"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>All Services</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            Building Construction
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setServices(e.target.value)}
                                                                                   value={'building construction'}
                                                                                   name={"services"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"}*/}
                                                                        {/*       id={'building-construction'}*/}
                                                                        {/*       value={'building construction'}*/}
                                                                        {/*       name={"services"}*/}
                                                                        {/*       onChange={(e) => setServices(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"building-construction"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>Building Construction</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            Bridges
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setServices(e.target.value)}
                                                                                   value={'bridge'}
                                                                                   name={"services"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'bridges'}*/}
                                                                        {/*       value={'bridges'} name={"services"}*/}
                                                                        {/*       onChange={(e) => setServices(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"bridges"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>Bridges</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            Track Linking
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setServices(e.target.value)}
                                                                                   value={'track linking'}
                                                                                   name={"services"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'track-linking'}*/}
                                                                        {/*       value={'track linking'} name={"services"}*/}
                                                                        {/*       onChange={(e) => setServices(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"track-linking"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>Track Linking</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                    <div
                                                                        className={"d-flex justify-content-start py-2 gap-3"}>
                                                                        <label
                                                                            className={"container-label font-size-16 font-weight-305 font-color-blue"}>
                                                                            Roads
                                                                            <input type={"radio"}
                                                                                   onChange={(e) => setServices(e.target.value)}
                                                                                   value={'roads'}
                                                                                   name={"services"}/>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        {/*<input type={"radio"} id={'roads'}*/}
                                                                        {/*       value={'roads'} name={"services"}*/}
                                                                        {/*       onChange={(e) => setServices(e.target.value)}/>*/}
                                                                        {/*<div>*/}
                                                                        {/*    <label htmlFor={"roads"}*/}
                                                                        {/*           className={"font-size-16 font-weight-305 font-color-blue"}>Roads</label>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/*  projects  */}
                            <div className={"row mt-4 pt-md-3 row-gap-5"}>
                                {filterData.length !== 0 ?
                                    (filterData.map(eachProject => (
                                        <div className={"col-md-4 col-12"} key={eachProject.id}>
                                            <Link to={`/project-details/${eachProject.project_slug}`}
                                                  style={{textDecoration: "none"}}>
                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        <div
                                                            className={"image-container overflow-hidden  position-relative b-2-light-brown br-16"}>
                                                            <img className={"img-fluid w-100"}
                                                                 src={eachProject.project_card_image}
                                                                 alt={"project-image"}/>
                                                        </div>
                                                        <div className={"w-100 d-flex flex-column mt-3"}>
                                                            <label
                                                                className={"font-color-orange font-size-14 font-weight-305"}>{eachProject.project_tag}</label>
                                                            <div className={"my-2"}>
                                                                <label
                                                                    className={"font-color-blue line-height-32 font-size-24 text-truncate w-100 font-weight-400 font-zaf-renaissance"}>
                                                                    {eachProject.project_name}
                                                                </label>
                                                            </div>
                                                            <label
                                                                className={"font-color-blue font-size-16 font-weight-305"}>{eachProject.project_client}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))) :
                                    (projects.map(eachProject => (
                                        <div className={"col-md-4 col-12"} key={eachProject.id}>
                                            <Link to={`/project-details/${eachProject.project_slug}`}
                                                  style={{textDecoration: "none"}}>
                                                <div className={"row"}>
                                                    <div className={"col-12"}>
                                                        <div
                                                            className={"image-container overflow-hidden  position-relative b-2-light-brown br-16"}>
                                                            <img className={"img-fluid w-100"}
                                                                 src={eachProject.project_card_image}
                                                                 alt={"project-image"}/>
                                                        </div>
                                                        <div className={"w-100 d-flex flex-column mt-3"}>
                                                            <label
                                                                className={"font-color-orange font-size-14 font-weight-305"}>{eachProject.project_tag}</label>
                                                            <div className={"my-2"}>
                                                                <label
                                                                    className={"font-color-blue line-height-32 font-size-24 font-zaf-renaissance text-truncate w-100 font-weight-400"}
                                                                    style={{verticalAlign: 'middle'}}>
                                                                    {eachProject.project_name}
                                                                </label>
                                                            </div>
                                                            <label
                                                                className={"font-color-blue font-size-16 font-weight-305"}>{eachProject.project_client}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )))}
                                {/*{projects.map(eachProject => (*/}
                                {/*    <div className={"col-md-4 col-12"} key={eachProject.id}>*/}
                                {/*        <Link to={`/project-details/${eachProject.project_slug}`}*/}
                                {/*              style={{textDecoration: "none"}}>*/}
                                {/*            <div className={"row"}>*/}
                                {/*                <div className={"col-12"}>*/}
                                {/*                    <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                         src={eachProject.project_card_image}*/}
                                {/*                         alt={"project-image"}/>*/}
                                {/*                    <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                        <label*/}
                                {/*                            className={"font-color-orange font-size-14 font-weight-305"}>{eachProject.project_tag}</label>*/}
                                {/*                        <div className={"my-2"}>*/}
                                {/*                            <label*/}
                                {/*                                className={"font-color-blue font-size-24 text-truncate w-100 font-weight-400"}>*/}
                                {/*                                {eachProject.project_name}*/}
                                {/*                            </label>*/}
                                {/*                        </div>*/}
                                {/*                        <label*/}
                                {/*                            className={"font-color-blue font-size-16 font-weight-305"}>{eachProject.project_location}</label>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </Link>*/}
                                {/*    </div>*/}
                                {/*))}*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-1.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label*/}
                                {/*                        className={"font-color-blue font-size-24 text-truncate w-100 font-weight-400"}>Road*/}
                                {/*                        Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-2.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 text-truncate w-100 font-weight-400"}>*/}
                                {/*                        New BG line between Nandayal-Yerragunta Stations*/}
                                {/*                    </label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-3.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 text-truncate w-100 font-weight-400"}>*/}
                                {/*                        New BG line between Nandayal-Yerragunta Stations*/}
                                {/*                    </label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-4.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 text-truncate w-100 font-weight-400"}>*/}
                                {/*                        Station building Bayappanahalli, Third coaching terminal*/}
                                {/*                    </label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/building-construction-image-1.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>*/}
                                {/*                    BUILDING CONSTRUCTION*/}
                                {/*                </label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400 text-truncate w-100"}>*/}
                                {/*                        Ladies Hostel Building*/}
                                {/*                    </label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>Banglore University</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/building-construction-image-1.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>*/}
                                {/*                    BUILDING CONSTRUCTION*/}
                                {/*                </label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400 text-truncate w-100"}>*/}
                                {/*                        Ladies Hostel Building*/}
                                {/*                    </label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>Banglore University</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"}*/}
                                {/*                 src={"/images/projects/track-linking-image-1.png"}*/}
                                {/*                 alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK*/}
                                {/*                    LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400"}>Road*/}
                                {/*                        Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South*/}
                                {/*                    Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"} src={"/images/projects/roads-image-1.png"} alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 text-truncate w-100 font-weight-400"}>Yelahanka-Penukonda Doubling</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"} src={"/images/projects/track-linking-image-1.png"} alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400"}>Road Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"} src={"/images/projects/track-linking-image-1.png"} alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400"}>Road Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"} src={"/images/projects/track-linking-image-1.png"} alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>TRACK LINKING</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400"}>Road Over Bridge</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={"col-md-4 col-12"}>*/}
                                {/*    <div className={"row"}>*/}
                                {/*        <div className={"col-12"}>*/}
                                {/*            <img className={"img-fluid w-100 b-2-light-brown br-16"} src={"/images/projects/bridge-image-1.png"} alt={"project-image"}/>*/}
                                {/*            <div className={"w-100 d-flex flex-column mt-3"}>*/}
                                {/*                <label className={"font-color-orange font-size-14 font-weight-305"}>BRIDGE</label>*/}
                                {/*                <div className={"my-2"}>*/}
                                {/*                    <label className={"font-color-blue font-size-24 font-weight-400 text-truncate w-100"}>Station building Bayappanahalli, Third coaching terminal</label>*/}
                                {/*                </div>*/}
                                {/*                <label className={"font-color-blue font-size-16 font-weight-305"}>South Central Railway</label>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    {/*  Have a project in mind?  */}
                    <div className={"row bg-promotion-container"}>
                        <div className={"col-12"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-md-8 py-md-5 py-4 my-md-5 "}>
                                    <div className={"row justify-content-center"}>
                                        <div className={"col-md-10 col-12"}>
                                            <label
                                                className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-orange"}>
                                                Have a project in mind?
                                            </label>
                                            <div className={"mt-4"}>
                                                <h3 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                                    We'll work closely with you to understand your needs and create a
                                                    custom
                                                    design that reflects your style and values.
                                                </h3>
                                            </div>
                                            <div className={"mt-4 mt-md-3"}>
                                                <Link to={"/contact-us"}>
                                                    <button type={"button"}
                                                            className={"bg-contact-us-btn font-sequel-sans-medium border-0 bg-orange font-color-blue font-size-16 font-weight-315"}>
                                                        Contact Us
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-4 d-md-block d-none p-0"}>
                                    <img className={"img-fluid w-100 h-100"}
                                         src={"/images/home/night-view-bridge-image.png"}
                                         alt={"night-view-bridge-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default Projects;
