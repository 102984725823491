import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import * as Icon from 'react-feather';
import {useEffect, useState} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const NavbarPage = () => {

    const [showDropDown, setShowDropDown] = useState(false);
    const [navItemsColor, setNavItemsColor] = useState('font-color-blue');
    const [navBgColor, setNavBgColor] = useState(true);
    const [dropDownColor, setDropDownColor] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(true);
    const location = useLocation();

    const handleScroll = () => {
        if (window.scrollY >= 100 && location.pathname === '/') {
            setNavItemsColor('font-color-blue');
            setDropDownColor(true);
            setNavBgColor(false);
            setMobileMenu(false)
        } else if (window.scrollY < 500 && location.pathname === '/') {
            setNavItemsColor('font-color-neutral-white');
            setDropDownColor(false);
            setNavBgColor(true);
            setMobileMenu(true)
        } else {
            setNavItemsColor('font-color-blue');
            setNavBgColor(false)
            setDropDownColor(true);
            setMobileMenu(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },);

    useEffect(() => {
        if (location.pathname === '/' && window.scrollY < 500) {
            setNavItemsColor('font-color-neutral-white');
            setNavBgColor(true);
            setDropDownColor(false);
            setMobileMenu(true);
        } else {
            setNavItemsColor('font-color-blue');
            setNavBgColor(false)
            setDropDownColor(true);
            setMobileMenu(false);
        }
    }, [location.pathname]);

    return (
        <div className={"row"}>
            {['md'].map((expand) => (
                <Navbar key={expand} expand={expand} fixed={"top"}
                        className={`${navBgColor ? 'bg-navbar' : 'bg-white'} col-12 py-3 d-md-none`}>
                    <Container fluid className={"row"}>
                        <Navbar.Brand className={"ps-4 col-md-3 col-5"}>
                            <div
                                className=" d-flex justify-content-center">
                                <Link to={"/"}>
                                    <img className={"img-fluid"}
                                         src={"/images/home/mvvs-logo.png"} alt={"logo"}/>
                                </Link>
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className={"col-2 p-0 border-0"}>
                            <span className={"border-0 w-100 p-0"}>
                                <Icon.AlignRight color={`${mobileMenu ? '#ffffff': '#081250' }`} size={24} />
                            </span>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            className={'h-100'}
                            placement="top"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <div className={"row"}>
                                        <div className={"col-6"}>
                                            <div
                                                className=" d-flex justify-content-md-start justify-content-center">
                                                <Link to={"/"}>
                                                    <img className={"img-fluid"}
                                                         src={"/images/home/mvvs-logo.png"}
                                                         alt={"logo"}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className={""}>
                                <Nav className="d-felx flex-column justify-content-start gap-3 align-items-center mt-4 p-0">
                                    <NavDropdown
                                        title="About us"
                                        className={`font-size-16 text-center p-0 font-weight-305`}
                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                    >
                                        <NavDropdown.Item>
                                            <Link to={'/about-us'}>
                                                <div className={"text-center"}>
                                                    <label
                                                        className={"font-size-14 font-weight-305 font-color-blue"}>Overview</label>
                                                </div>
                                            </Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link to={'/team'}>
                                                <div className={"text-center"}>
                                                    <label
                                                        className={"font-size-14 font-weight-305 font-color-blue"}>Team</label>
                                                </div>
                                            </Link>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item>
                                            <Link to={'/experience'}>
                                                <div className={"text-center"}>
                                                    <label
                                                        className={"font-size-14 font-weight-305 font-color-blue"}>Experience</label>
                                                </div>
                                            </Link>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav className={""}>
                                        <Link to={"/services"}>
                                            <button type="button"
                                                    className={`bg-transparent font-color-blue border-0 font-size-16 font-weight-305`}>
                                                Services
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav className="">
                                        <Link to={"/projects"}>
                                            <button type="button"
                                                    className={`bg-transparent border-0 font-color-blue font-size-16 font-weight-305`}>
                                                Projects
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav className="">
                                        <Link to={"/achievements"}>
                                            <button type="button"
                                                    className={`bg-transparent font-color-blue border-0 font-size-16 font-weight-305`}>
                                                Achievements
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav className="">
                                        <Link to={"/financial"}>
                                            <button type="button"
                                                    className={`bg-transparent font-color-blue border-0 font-size-16 font-weight-305`}>
                                                Financials
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav className="">
                                        <Link to={"/careers"}>
                                            <button type="button"
                                                    className={`bg-transparent font-color-blue border-0 font-size-16 font-weight-305`}>
                                                Career
                                            </button>
                                        </Link>
                                    </Nav>
                                    <Nav className={"mt-5 pt-3 gap-3 d-flex flex-row justify-content-center align-items-center"}>
                                        <div className={""}>
                                            <Icon.Mail size={19} color={"#081250"}/>
                                        </div>
                                        <a href={"mailto:admin@mvvs.info"} className={"font-color-blue text-decoration-none font-size-16 font-weight-315 font-sequel-sans-medium"}>admin@mvvs.info</a>
                                    </Nav>
                                    <Nav className={"fixed-bottom d-flex justify-content-center px-4 py-4"}>
                                        <Link to={"/contact-us"}>
                                            <button
                                                className={"bg-contact-us-btn w-100 font-sequel-sans-medium bg-orange border-0 font-size-20 font-color-blue font-weight-400"}>
                                                Contact us
                                            </button>
                                        </Link>
                                    </Nav>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
            <Navbar expand="md" fixed={"top"} className={`${navBgColor ? 'bg-navbar' : 'bg-white'} col-12 d-md-flex d-none justify-content-center py-0`}>
                <Container className={"row d-flex justify-content-center py-4"}>
                    <Navbar.Brand className={"col-md-3 col-9"}>
                        <div className={"row"}>
                            <div className="col-6 col-md-8 p-0">
                                <div className=" d-flex justify-content-md-start justify-content-center">
                                    <Link to={"/"}>
                                        <img className={"img-fluid"} src={"/images/home/mvvs-logo.png"} alt={"logo"}/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={"col-2 border-0"}/>
                    <Navbar.Collapse id="basic-navbar-nav"
                                     className={"d-md-flex flex-column align-items-md-end align-items-center col-md-9 p-0"}>
                        <Nav className={"d-md-flex d-none"}>
                            <div className={"d-flex justify-content-start align-items-center gap-2"}>
                                <Icon.Mail color={`${dropDownColor ? '#081250' :'#FFFFFF'}`} size={19}/>
                                <div className={""}>
                                    <a href={"mailto:admin@mvvs.info"} className={""}>
                                        <label
                                            className={`font-size-16 ${navItemsColor} font-weight-315 font-sequel-sans-medium services-button`}>admin@mvvs.info</label>
                                    </a>
                                </div>
                            </div>
                            {/*<div className={`font-color-neutral-white me-4 ${dropDownColor ? 'nav-blue-border':'nav-white-border'} `}>*/}
                            {/*</div>*/}
                            {/*<div className={"d-flex justify-content-start align-items-center gap-2"}>*/}
                            {/*    <div className={""}>*/}
                            {/*        <a href={"mailto:mvvs.blr@gmail.com"}>*/}
                            {/*            <label*/}
                            {/*                className={`font-size-16 ${navItemsColor} font-weight-315 font-sequel-sans-medium services-button`}>mvvs.blr@gmail.com</label>*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </Nav>
                        <Nav className="me-left align-items-center flex-wrap gap-md-3 gap-3 mt-md-4 pt-md-1">
                            <NavDropdown
                                title="About us"
                                className={`font-size-16 nav-bar-item font-weight-305 ${dropDownColor === true && 'navbar-scroll'}`}
                                onMouseEnter={() => setShowDropDown(true)}
                                onMouseLeave={() => setShowDropDown(false)}
                                show={showDropDown}
                                id={`offcanvasNavbarDropdown-expand`}
                            >
                                <NavDropdown.Item>
                                    <Link to={'/about-us'}>
                                        <div>
                                            <label
                                                className={"font-size-14 nav-bar-item font-weight-305 font-color-blue"}>Overview</label>
                                        </div>
                                    </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Link to={'/team'}>
                                        <div>
                                            <label
                                                className={"font-size-14 nav-bar-item font-weight-305 font-color-blue"}>Team</label>
                                        </div>
                                    </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Link to={'/experience'}>
                                        <div>
                                            <label
                                                className={"font-size-14 nav-bar-item font-weight-305 font-color-blue"}>Experience</label>
                                        </div>
                                    </Link>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav className="">
                                <Link to={"/services"}>
                                    <button type="button"
                                            className={`bg-transparent nav-bar-item border-0 font-size-16 ${navItemsColor} font-weight-305`}>
                                        Services
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className="">
                                <Link to={"/projects"}>
                                    <button type="button"
                                            className={`bg-transparent nav-bar-item border-0 font-size-16 ${navItemsColor} font-weight-305`}>
                                        Projects
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className="">
                                <Link to={"/achievements"}>
                                    <button type="button"
                                            className={`bg-transparent nav-bar-item border-0 font-size-16 ${navItemsColor} font-weight-305`}>
                                        Achievements
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className="">
                                <Link to={"/financial"}>
                                    <button type="button"
                                            className={`bg-transparent nav-bar-item border-0 font-size-16 ${navItemsColor} font-weight-305`}>
                                        Financials
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className="">
                                <Link to={"/careers"}>
                                    <button type="button"
                                            className={`bg-transparent border-0 nav-bar-item font-size-16 ${navItemsColor} font-weight-305`}>
                                        Career
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className={"align-self-center"}>
                                <Link to={"/contact-us"}>
                                    <button
                                        className={"bg-contact-us-btn font-sequel-sans-medium bg-blue border-0 font-size-20 font-color-neutral-white font-weight-400"}>
                                        Contact us
                                    </button>
                                </Link>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavbarPage;
