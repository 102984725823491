import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import {useEffect} from "react";

const Team = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12 other-page-nav-color"}>

                    {/*  navbar  */}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  team  */}
                    <div className={"row justify-content-center my-3 my-md-5"}>
                        <div className={"col-md-10 col-11 my-3"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Our Team</label>
                            </div>

                            <div className={"row mt-4 justify-content-center gap-5 pt-md-3"}>
                                <div
                                    className={"col-md-5 col-12 py-md-2 b-1-light-brown team-card d-flex flex-column justify-content-center br-16 position-relative"}>
                                    <div className={"row justify-content-center p-md-4 p-3"}>
                                        <div className={"col-10 p-md-4"}>
                                            <img className={"img-fluid w-100"} src={"/images/team/mr-mvvs-image.png"}
                                                 alt={"mr-mvvs-image"}/>
                                        </div>
                                        <div className={"col-12 mt-md-3 mt-2"}>
                                            <div className={"d-flex flex-column align-items-center"}>
                                                <label
                                                    className={"font-color-orange font-size-32 font-zaf-renaissance font-weight-400"}>Mr.M.V.V.Satyanarrayana</label>
                                                <div className={"mt-md-2 mt-1"}>
                                                    <label className={"font-size-18 font-weight-305 font-color-blue"}>Managing
                                                        Partner</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={"row d-flex flex-column justify-content-center h-100 team-details-container position-absolute"}>
                                        <div className={"col-12 text-center"}>
                                            <div
                                                className={"d-flex w-100 h-100 p-md-4 p-3 flex-column align-items-center"}>
                                                <label
                                                    className={"font-size-32 font-weight-400 font-zaf-renaissance user-select-none  font-color-neutral-white"}> Mr.M.V.V.Satyanarrayana</label>
                                                <div className={"mt-2 mb-4 pb-md-3"}>
                                                    <label className={"font-size-18 font-weight-305 user-select-none  font-sequel-sans-medium font-color-orange"}>Managing
                                                        Partner</label>
                                                </div>
                                                <label
                                                    className={"font-size-18 font-weight-305 user-select-none  font-color-neutral-white"}>A
                                                    paragon of integrity with over 40 years in construction, Mr.
                                                    Satyanarrayana’s leadership ensures our projects uphold the highest
                                                    quality and engineering standards. He has a diverse portfolio,
                                                    including office buildings, hospitals, and the World Class Air
                                                    Conditioned Railway Station Terminal in Bangalore. His profound
                                                    knowledge of construction and project management has rightfully
                                                    earned him industry legend status.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"col-md-5 col-12 py-md-2 b-1-light-brown team-card d-flex flex-column justify-content-center br-16 position-relative"}>
                                    <div className={"row justify-content-center p-md-4 p-3"}>
                                        <div className={"col-10 p-md-4"}>
                                            <img className={"img-fluid w-100"}
                                                 src={"/images/team/mr-m-bhima-shanker-image.png"}
                                                 alt={"mr-m-bhima-shanker-image"}/>
                                        </div>
                                        <div className={"col-12 mt-md-3 mt-2"}>
                                            <div className={"d-flex flex-column align-items-center"}>
                                                <label className={"font-color-orange font-size-32  font-zaf-renaissance font-weight-400"}>Mr.M.Bhima
                                                    Shanker</label>
                                                <div className={"mt-md-2 mt-1"}>
                                                    <label
                                                        className={"font-size-18 font-weight-305 font-color-blue"}>Partner</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={"row d-flex flex-column justify-content-center h-100 team-details-container position-absolute"}>
                                        <div className={"col-12 w-100 text-center"}>
                                            <div
                                                className={"d-flex w-100 p-md-4 p-3 h-100 flex-column align-items-center"}>
                                                <label
                                                    className={"font-size-32 font-weight-400 font-zaf-renaissance user-select-none  font-color-neutral-white"}>Mr.M.Bhima
                                                    Shanker</label>
                                                <div className={"mt-2 mb-4 pb-md-3"}>
                                                    <label
                                                        className={"font-size-18 font-weight-305 font-sequel-sans-medium user-select-none  font-color-orange"}>Partner</label>
                                                </div>
                                                <label
                                                    className={"font-size-18 font-weight-305 user-select-none  font-color-neutral-white"}>A
                                                    paragon of integrity with over 40 years in construction, Mr.M.Bhima
                                                    Shanker leadership ensures our projects uphold the highest
                                                    quality and engineering standards. He has a diverse portfolio,
                                                    including office buildings, hospitals, and the World Class Air
                                                    Conditioned Railway Station Terminal in Bangalore. His profound
                                                    knowledge of construction and project management has rightfully
                                                    earned him industry legend status.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default Team;
