import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
import {InView} from "react-intersection-observer";

const AboutUs = () => {

    const splideRef = useRef(null);
    const splideClientsRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12"}>
                    {/*  hero section  */}
                    <div className={"row"}>
                        <div className={"col-12 other-page-nav-color"}>
                            {/*  navbar  */}
                            <NavbarPage/>

                            <div className={"row"}>
                                <div className={"col-12 my-md-4 py-5"}></div>
                            </div>

                            <div className={"row justify-content-center mt-md-5 mt-4 pt-2"}>
                                <div className={"col-md-10 col-11"}>
                                    <div className={"w-100 d-flex flex-column text-center"}>
                                        <h1 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>
                                            Transforming Hyderabad's skyline by delivering top-notch construction
                                            services
                                        </h1>
                                    </div>
                                </div>
                                <div className={"col-md-6 col-12 text-center px-md-0 px-3"}>
                                    <div className={"mt-md-4 mt-2"}>
                                        <label className={"font-size-18 font-weight-305 font-color-blue"}>Our buildings
                                            combine minimalism and elegance of lines and shapes. We
                                            want them to be an integral part of the surrounding landscape.</label>
                                    </div>
                                    <div className={"mt-4 pt-md-3"}>
                                        <Link to={"/contact-us"}>
                                            <button type={"button"}
                                                    className={"bg-contact-us-btn font-sequel-sans-medium border-0 bg-orange font-color-blue font-size-16 font-weight-315"}>Contact
                                                us
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* projects slider */}
                    <div className={"row py-4"}>
                        <div className={"col-12 px-0"}>
                            <InView rootMargin={"-50% 0% -50% 0%"} onChange={(inView, entry) => {
                                const splide = splideRef.current?.splide;
                                if(splide) {
                                    if(inView) {
                                        splide.Components.AutoScroll.play();
                                    }
                                    else {
                                        splide.Components.AutoScroll.pause();
                                    }
                                }
                            }}>
                                <Splide tag={"div"}
                                        ref={splideRef}
                                        options={
                                            {
                                                type: 'loop',
                                                gap: '10px',
                                                drag: 'free',
                                                arrows: false,
                                                pagination: false,
                                                perPage: 3,
                                                perMove: 1,
                                                autoScroll: {
                                                    autoStart: false,
                                                    pauseOnHover: false,
                                                    rewind: false,
                                                    speed: 1.5,
                                                },
                                                breakpoints: {
                                                    767: {
                                                        perPage: 2
                                                    }
                                                }
                                            }
                                        }
                                        className={"py-2 my-md-5"}
                                        extensions={{AutoScroll}}
                                >
                                    <SplideSlide>
                                        <img src={"/images/about/about-us-roads-image.png"} className={"w-100 img-fluid"}
                                             alt={"about-us-roads-image"}/>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={"/images/about/about-us-building-image.png"} className={"w-100 img-fluid"}
                                             alt={"about-us-building-image"}/>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={"/images/about/about-us-rail-way-image.png"} className={"w-100 img-fluid"}
                                             alt={"about-us-rail-way-image"}/>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={"/images/about/about-us-roads-image.png"} className={"w-100 img-fluid"}
                                             alt={"about-us-roads-image"}/>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={"/images/about/about-us-building-image.png"} className={"w-100 img-fluid"}
                                             alt={"about-us-building-image"}/>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={"/images/about/about-us-rail-way-image.png"}
                                             className={"w-100 img-fluid"}
                                             alt={"about-us-rail-way-image"}/>
                                    </SplideSlide>
                                </Splide>
                            </InView>

                            {/*<img className={"img-fluid w-100"} src={"/images/about/about-us-hero-image.png"}*/}
                            {/*     alt={"about-us-hero-image"}/>*/}
                        </div>
                    </div>

                    {/* clients logos */}
                    <div className={"row"}>
                        <div className={"col-12 mb-md-5 mb-4 pb-2 px-0"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label
                                    className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-blue"}>Our
                                    Top
                                    Clients</label>
                            </div>

                            <InView rootMargin={"-50% 0% -50% 0%"} onChange={(inView, entry) => {
                                const splide = splideClientsRef.current?.splide;
                                if(splide) {
                                    if(inView) {
                                        splide.Components.AutoScroll.play();
                                    }
                                    else {
                                        splide.Components.AutoScroll.pause();
                                    }
                                }
                            }}>
                                <Splide tag={"div"} className={"w-100 mt-4 pt-3"}
                                        ref={splideClientsRef}
                                        options={
                                            {
                                                type: 'loop',
                                                gap: '20px',
                                                drag: 'free',
                                                arrows: false,
                                                pagination: false,
                                                perPage: 5,
                                                perMove: 1,
                                                breakpoints: {
                                                    767: {
                                                        perPage: 3,
                                                        gap: '20px'
                                                    }
                                                },
                                                autoScroll: {
                                                    autoStart: false,
                                                    pauseOnHover: false,
                                                    rewind: false,
                                                    speed: 1.5,
                                                }
                                            }
                                        }
                                        extensions={{AutoScroll}}
                                        aria-label="My Favorite Images">
                                    <SplideSlide>
                                        <div className={"img-container"}>
                                            <img src={"/images/about/indian-railway-logo.png"} className={"img-fluid"}
                                                 alt={"logos"}/>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <div className={"img-container"}>
                                            <img src={"/images/about/nhai-logo.png"} className={"img-fluid"} alt={"logos"}/>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <div className={"img-container"}>
                                            <img src={"/images/about/government-logo.png"} className={"img-fluid"}
                                                 alt={"logos"}/>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <div className={"img-container"}>
                                            <img src={"/images/about/nhidcl-logo.png"} className={"img-fluid"}
                                                 alt={"logos"}/>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <div className={"img-container"}>
                                            <img src={"/images/about/morth-logo.png"} className={"img-fluid"}
                                                 alt={"logos"}/>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <div className={"img-container"}>
                                            <img src={"/images/about/bbmp-logo.png"} className={"img-fluid"} alt={"logos"}/>
                                        </div>
                                    </SplideSlide>
                                </Splide>
                            </InView>
                        </div>
                    </div>

                    {/*  about us  */}
                    <div className={"row bg-blue justify-content-center py-md-1 py-2"}>
                        <div className={"col-md-10 col-11 py-md-5 py-4"}>
                            <div className={"row justify-content-between"}>
                                <div className={"col-md-6 col-12"}>
                                    <div className={"w-100 main-heading d-flex flex-column justify-content-start"}>
                                        <label
                                            className={"font-color-orange font-sequel-sans-medium font-weight-315 font-size-18"}>About
                                            us</label>
                                        <div className={"mt-3 w-100"}>
                                            <h2 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-neutral-white"}>
                                                From Vision to Reality: Our Journey of Innovation and Excellence
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-6 col-12 mt-4 mt-md-0"}>
                                    <div className={"w-100 d-flex flex-column justify-content-start"}>
                                        <label className={"font-color-neutral-white font-weight-305 font-size-18"} style={{textAlign: 'justify'}}>
                                            MVVS (M/s.M.V.V.SATYANARRAYANA) is a construction firm based in India that
                                            specializes in Infrastructure Projects. The company was founded in 1984 as a
                                            partnership firm. MVVS has a presence across India, with offices in two
                                            cities and headquarters in Hyderabad.
                                        </label>
                                        <div className={"mt-4"}>
                                            <label className={"font-color-neutral-white font-weight-305 font-size-18"}>
                                                The firm's work includes:
                                            </label>
                                            <div>
                                                <label
                                                    className={"font-color-neutral-white font-weight-305 font-size-18"} style={{textAlign: 'justify'}}>
                                                    Civil construction, Structural construction, Electrical
                                                    construction,
                                                    Sanitary construction, Plumbing construction, Bridge construction,
                                                    Road
                                                    construction. MVVS also works in the highways, building and railways
                                                    sectors.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-12 mt-4 pt-md-3"}>
                                    <img className={"img-fluid w-100 br-16"} src={"/images/about/about-us-hospital-image.png"}
                                         alt={"about-us-hospital-image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  our values  */}
                    <div className={"row justify-content-center bg-white"}>
                        <div className={"col-11 col-md-10 my-md-5 my-4 py-2"}>
                            <div className={"row justify-content-center mb-2"}>
                                <div className={"col-12 d-flex justify-content-center align-items-center"}>
                                    <label
                                        className={"font-size-18 font-weight-315 font-sequel-sans-medium font-color-orange"}>Our
                                        values</label>
                                </div>
                                <div className={"col-md-10 col-12 mt-3 text-center"}>
                                    <h2 className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Building
                                        Excellence
                                        Through Integrity and Innovation</h2>
                                </div>
                                <div className={"col-md-8 col-12 mt-md-4 mt-3 text-md-center"}>
                                    <label className={"font-size-18 font-weight-305 font-color-blue"} >
                                        At our construction company, we prioritise integrity, innovation, quality,
                                        safety, sustainability, and client satisfaction, delivering exceptional
                                        construction services that transform visions into reality.
                                    </label>
                                </div>
                            </div>

                            {/*  content  */}
                            <div className={"row pt-md-5 mt-4 gap-md-0 gap-4"}>
                                <div className={"col-12 col-md-4"}>
                                    <div className={"row justify-content-center"}>
                                        <div
                                            className={"col-md-2 col-2 p-0 d-flex flex-column align-items-center text-center"}>
                                            <img src={"/images/about/mission-goal-success-image.png"}
                                                 className={"img-fluid w-100"} alt={"mission-goal-success-image"}/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div
                                            className={"mt-md-3 mt-2 col-12 text-center d-flex flex-column justify-content-center align-items-center"}>
                                            <label
                                                className={"font-size-32 font-weight-400 font-color-blue font-zaf-renaissance"}>Mission</label>
                                            <div className={"mt-md-3 mt-2"}>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-opacity-80 font-color-blue"}>
                                                    To understand our client’s needs, plan, build, and deliver
                                                    high-quality projects that exceed expectations without exceeding the
                                                    budget.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12 col-md-4 mt-3 mt-md-0"}>
                                    <div className={"row justify-content-center"}>
                                        <div
                                            className={"col-md-2 col-2 p-0 d-flex flex-column align-items-center text-center"}>
                                            <img src={"/images/about/vision-image.png"} className={"img-fluid w-100"}
                                                 alt={"vision-image"}/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div
                                            className={"mt-md-3 mt-2 col-12 text-center d-flex flex-column justify-content-center align-items-center"}>
                                            <label
                                                className={"font-size-32 font-weight-400 font-color-blue font-zaf-renaissance"}>Vision</label>
                                            <div className={"mt-md-3 mt-2"}>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-opacity-80 font-color-blue"}>
                                                    Our vision is to be the trusted leader globally in building
                                                    SAFE,STRONG and LONG LASTING STRUCTURES.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12 col-md-4 mt-3 mt-md-0"}>
                                    <div className={"row justify-content-center"}>
                                        <div
                                            className={"col-md-2 col-2 p-0 d-flex flex-column align-items-center text-center"}>
                                            <img src={"/images/about/goal-flag-image.png"}
                                                 className={"img-fluid w-100"} alt={"goal-flag-image"}/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div
                                            className={"mt-md-3 mt-2 col-12 text-center d-flex flex-column justify-content-center align-items-center"}>
                                            <label
                                                className={"font-size-32 font-weight-400 font-color-blue font-zaf-renaissance"}>Goal</label>
                                            <div className={"mt-md-3 mt-2"}>
                                                <label
                                                    className={"font-size-18 font-weight-305 font-color-blue font-opacity-80"}>
                                                    Our values are to deliver Quality Work, Prioritising Safety,
                                                    Honesty, Being Reliable, and Embracing Innovation.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer  */}
                    <FooterPage/>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
