import NavbarPage from "../Navbar/navbarPage";
import FooterPage from "../Footer/footerPage";
import {useEffect, useState} from "react";
import {TailSpin} from "react-loader-spinner";

const ContactUs = () => {

    const [loadingButton, setLoadingButton] = useState(false);
    const [showMsg, setShowMsg] = useState('')

    const handleSubmitButton = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('contact-form');
        const data = new FormData(form);
        console.log(data)
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                setLoadingButton(false);
                setShowMsg('success');
                form.reset();
            }).catch(() => {
            setLoadingButton(false);
            setShowMsg('error')
            form.reset();
        });
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12 other-page-nav-color"}>
                    {/* navbar*/}
                    <NavbarPage/>

                    <div className={"row"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  contact us  */}
                    <div className={"row"}>
                        <div className={"col-12 py-md-5 py-3 my-3"}>
                            <div className={"row justify-content-center "}>
                                <div className={"col-md-10 col-11"}>
                                    <div className={"w-100 d-flex justify-content-center"}>
                                        <label
                                            className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Contact
                                            Us</label>
                                    </div>
                                    <form className={"row mt-4 justify-content-center pt-md-3"} id={"contact-form"}
                                          onSubmit={handleSubmitButton} method={"POST"}
                                          action={"https://script.google.com/macros/s/AKfycbx_ofLL7ZSHx-opaKlB4nDyAuloOkIEZMsJfhhHBAVp7MieMgegQ09vwLWm5LnIMmsUNA/exec"}>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>First
                                                        Name and
                                                        Last Name</label>
                                                </div>
                                                <div className={"col-md-3 col-12 ps-md-0"}>
                                                    <input type={"text"} name={"FirstName"} className={"form-control"}
                                                           placeholder={"Enter your first name"}/>
                                                </div>
                                                <div className={"col-md-3 col-12 ps-md-0"}>
                                                    <input type={"text"} name={"LastName"} className={"form-control"}
                                                           placeholder={"Enter your last name"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Email
                                                        Address</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"email"} name={"Email"} className={"form-control"}
                                                           placeholder={"Enter your mail id"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Business
                                                        Name</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"text"} name={"BusinessName"}
                                                           className={"form-control font-size-18 font-weight-305 font-color-dark-grey"}
                                                           placeholder={"Enter your business name"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12 "}>
                                                    <label
                                                        className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Phone
                                                        Number</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"number"} name={"PhoneNumber"}
                                                           className={"form-control font-size-18 font-weight-305 font-color-dark-grey"}
                                                           placeholder={"Enter your phone number"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 form-field-border p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Subject</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                                    <input type={"text"} name={"Subject"}
                                                           className={"form-control font-size-18 font-weight-305 font-color-dark-grey"}
                                                           placeholder={"Enter your project needs"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 p-0"}>
                                            <div
                                                className={"row justify-content-md-between align-items-center py-4 gap-md-0 gap-3"}>
                                                <div className={"col-md-6 col-12"}>
                                                    <label
                                                        className={"font-size-16 font-weight-315 font-sequel-sans-medium font-color-blue"}>Message</label>
                                                </div>
                                                <div className={"col-md-6 col-12 ps-md-0"}>
                                        <textarea type={"text"} rows={4} name={"Message"}
                                                  className={"form-control font-size-18 font-weight-305 font-color-dark-grey"}
                                                  placeholder={"Tell us about the project"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 py-md-4 py-3 px-0"}>
                                            <div className={"row justify-content-end"}>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"row"}>
                                                        <button type={"submit"} disabled={loadingButton}
                                                                className={"bg-orange col-12 col-md-4 border-0 bg-contact-us-btn font-sequel-sans-medium font-color-blue font-size-16 font-weight-315"}>
                                                            {loadingButton ? (<div
                                                                    className={"d-inline-flex align-items-center gap-2"}>
                                                                    <TailSpin color={'#081250'} width={24} height={24}/>
                                                                    Submit
                                                                </div>) :
                                                                ('Submit')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-12 pb-md-4 pb-3"}>
                                            <div className={"row justify-content-end"}>
                                                <div className={"col-md-6"}>
                                                    {showMsg === 'success' && (
                                                        <div className={"w-100 bg-green-light br-8 p-md-4 p-3"}>
                                                            <label
                                                                className={"font-size-18 font-weight-315 font-color-green font-sequel-sans-medium"}>Thank
                                                                you for contacting us! Your message has been
                                                                successfully
                                                                received. We will get back to you shortly.</label>
                                                        </div>
                                                    )}
                                                    {showMsg === 'error' && (
                                                        <div className={"w-100 bg-light-red br-8 p-md-4 p-3"}>
                                                            <label
                                                                className={"font-size-18 font-weight-315 text-danger font-sequel-sans-medium"}>Thank
                                                                Something went wrong while submitting your message. Please try
                                                                again later. Thank you for your understanding.
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  footer   */}
                    <FooterPage/>
                </div>
            </div>
        </div>

    )
}

export default ContactUs;
