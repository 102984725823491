import NavbarPage from "../Navbar/navbarPage";
import {Chart, registerables} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useEffect, useRef} from "react";
import FooterPage from "../Footer/footerPage";

const Financial = () => {

    const chartRef = useRef(null); // Use a ref to keep track of the chart instance

    const barChart = async () => {
        const data = [
            {year: '2016-17', count: 59.92},
            {year: '2017-18', count: 74.06},
            {year: '2018-19', count: 62.58},
            {year: '2019-20', count: 73.59},
            {year: '2020-21', count: 76.99},
            {year: '2021-22', count: 131.03},
            {year: '2022-23', count: 185.17},
            {year: '2023-24', count: 258.81},
        ];

        const maxCount = 280;

        const barChartEleId = document.getElementById('barChart');

        if (barChartEleId) {
            // Destroy any existing chart instance before creating a new one
            if (chartRef.current) {
                chartRef.current.destroy();
            }

            // Register all required components for the chart
            Chart.register(...registerables);
            Chart.register(ChartDataLabels);

            // Create new chart instance and save it in ref
            chartRef.current = new Chart(barChartEleId, {
                type: 'bar',
                data: {
                    labels: data.map(row => row.year),
                    datasets: [
                        {
                            data: data.map(row => row.count),
                            backgroundColor: 'rgba(8, 18, 80, 0.8)',
                            datalabels: {
                                display: true,
                                color: '#FFFFFF',
                                formatter: (value) => `${value}`,
                                anchor: 'end',
                                align: 'top',
                                offset: window.innerWidth <= 768 ? -40: -30,
                                font: {
                                    size: 10,
                                    weight: 315,
                                },
                                fontFamily: 'Sequel Sans Medium',
                                rotation: window.innerWidth <= 768 ? 270: 0
                            }
                        },
                        {
                            data: data.map(row => maxCount - row.count),
                            backgroundColor: '#0000000D',
                            datalabels: {
                                display: false
                            }
                        },

                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                display: false, // Hide vertical grid lines (x-axis)
                            },
                            title: {
                                display: true, // Show the title
                                text: 'Financial Year', // X-axis title text
                                color: '#081250', // Title color
                                fontFamily: "Zapf Renaissance",
                                font: {
                                    size: window.innerWidth <= 768 ? 14: 24, // Title font size
                                    weight: '400',// Title font weight
                                },
                                padding: {
                                    top: 8
                                }
                            },
                            ticks: {
                                display: true, // Hide x-axis labels
                                color: '#081250',
                                fontFamily: 'Sequel Sans Medium',
                            }
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: false // Hide horizontal grid lines (y-axis)
                            },
                            title: { // Adding X-axis title
                                display: true, // Show the title
                                text: 'Contractual Turnover in Crores', // X-axis title text
                                color: '#081250', // Title color
                                fontFamily: 'Zapf Renaissance',
                                font: {
                                    size: window.innerWidth <= 768 ? 14: 24, // Title font size
                                    weight: '400',// Title font weight
                                },
                                padding: {
                                    bottom: 10
                                }
                            },
                            ticks: {
                                display: true,
                                color: '#081250',
                                font: {
                                    size: 10
                                },
                                stepSize: 40, // Set the step size to 40
                                beginAtZero: true// Hide y-axis labels
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false // Ensure the legend is not shown
                        },
                        datalabels: {
                            clip: true
                        }
                    }
                },

            });
        } else {
            console.error("Canvas with ID 'barChart' not found");
        }
    };

    useEffect(() => {
        barChart();

        // Cleanup: Destroy chart instance when component unmounts
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className={"container-fluid"}>
            <div className={"row bg-white"}>
                <div className={"col-12"}>

                    <div className={"row bg-white"}>
                        <div className={"col-12 other-page-nav-color"}>
                            {/*  navbar  */}
                            <NavbarPage/>
                        </div>
                    </div>

                    <div className={"row bg-white"}>
                        <div className={"col-12 py-5 my-md-4"}></div>
                    </div>

                    {/*  bar chart */}
                    <div className={"row justify-content-center my-md-5"}>
                        <div className={"col-11 col-md-10 my-3 py-3 py-md-0"}>
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <label className={"font-size-56 font-weight-400 font-zaf-renaissance font-color-blue"}>Financial
                                    Turnover</label>
                            </div>

                            <div className={"row justify-content-center mt-4 pt-md-3"}>
                                <div className={"col-12 p-0 b-1-light-brown br-16"}>
                                    <canvas id="barChart" className={"p-md-4 p-3 font-sequel-sans-medium financial-graph-container my-md-2"}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>

                {/*  footer  */}
                    <FooterPage />
                </div>
            </div>
        </div>
    )
}

export default Financial;
